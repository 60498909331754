import { createRouter, createWebHistory} from 'vue-router'
import { baseRoute , getTagID, getOpenId, } from '../utils/util'
import echartRoute from './distribution'

// L: lottery
const _baseRoute = baseRoute()

const routes = [
    {
        path: `${_baseRoute}/`, 
        meta: {
            title: '银行渠道投注',
            keepAlive: true
        },
        component: () => import('../pages/index.vue'),
        children: [
            {
                path: '', // 普通投注主页
                meta: {
                    title: '上海福彩电话投注',
                    hiddenBack: true,
                },
                component: () => import('../pages/normalLotteryHome.vue')
            },
            {
                path: `bankMultiIntro`, 
                meta: {
                    title: '银行多期介绍',
                    hiddenBack: true,
                },
                component: () => import('../pages/BankMultiperiodIntro.vue')
            },
            {
                path: 'bankProduct',
                meta: {
                    title: '合作银行',
                    hiddenBack: true,
                },
                component: () => import('../pages/bankProduct.vue')
            },
            {
                path: 'bankMulti',
                meta: {
                    title: '多期选号',
                    hiddenBack: true,
                },
                component: () => import('../pages/constLottery/index.vue')
            },
            {
                path: 'coBanks',
                meta: {
                    title: '合作银行',
                    hiddenBack: true,
                },
                component: () => import('../pages/subIndex/PhoneLotteryComplain.vue')
            },
            {
                path: 'bonus',
                meta: {
                    title: '开奖公告',
                    hiddenBack: true,
                },
                component: () => import('../pages/winCode.vue')
            },
            {
                path: 'termly',
                meta: {
                    title: '多期产品',
                    hiddenBack: true,
                },
                component: () => import('../pages/constLottery/index.vue')
            },
            {
                path: 'mine',
                meta: {
                    title: '我的',
                    hiddenBack: true,
                },
                component: () => import('../pages/mine.vue')
            },
            
        ]
    },
    {
        path: `${_baseRoute}/message-order`,
        meta: {
            title: '短信指令',
        },
        component: () => import('../pages/messageOrder.vue')
    },
    {
        path: `${_baseRoute}/activity`,
        meta: {
            title: '活动'
        },
        component: () => import('../pages/submine/Activity.vue')
    },
    {
        path: `${_baseRoute}/voiceTutor`,
        meta: {
            title: '语音投注说明',
        },
        component: () => import('../pages/submine/PhoneProcess.vue')
    },
    {
        path: `${_baseRoute}/applyPhone`, 
        meta: {
            title: '申请手机号'
        },
        component: () => import('../pages/applyPhoneNumber/index.vue')
    },
    {
        path: `${_baseRoute}/constL?type=bank`, 
        meta: {
            title: '多期产品',
            keepAlive: true
        },
        component: () => import('../pages/constLottery/index.vue')
    },
    {
        path: `${_baseRoute}/LProcess`, 
        meta: {
            title: '银行产品流程',
        },
        component: () => import('../pages/subIndex/lotteryProcess.vue')
    },
    {
        path: `${_baseRoute}/withdrawSuccess`, 
        meta: {
            title: '提现成功'
        },
        component: () => import('../pages/submine/withdrawSuccess.vue')
    },
    {
        path: `${_baseRoute}/bindPhone`, 
        meta: {
            title: '绑定手机'
        },
        component: () => import('../pages/submine/bindPhone.vue')
    },
    {
        path: `${_baseRoute}/chooseNumber`,
        meta: {
            title: '选号',
            hiddenBack: true
        },
        component: () => import('../pages/chooseNumber.vue')
    },
    {
        path: `${_baseRoute}/LList`, 
        meta: {
            title: '选号单'
        },
        component: () => import('../pages/lotteryList.vue')
    },
    {
        path: `${_baseRoute}/changePassword`, 
        meta: {
            title: '修改提现密码'
        },
        component: () => import('../pages/submine/changePassword.vue')
    },
    {
        path: `${_baseRoute}/accountSafe`, 
        meta: {
            title: '账户安全'
        },
        component: () => import('../pages/submine/AccountSafe.vue')
    },
    {
        path: `${_baseRoute}/switchAccount`, 
        meta: {
            title: '账号切换'
        },
        component: () => import('../pages/submine/SwitchAccount.vue')
    },
    {
        path: `${_baseRoute}/fillInfo`, 
        meta: {
            title: '信息完善'
        },
        component: () => import('../pages/submine/realNameAuthor.vue')
    },
    {
        path: `${_baseRoute}/withdraw`, 
        meta: {
            title: '提现'
        },
        component: () => import('../pages/submine/withdraw.vue')
    },
    {
        path: `${_baseRoute}/wincode`, 
        meta: {
            title: '开奖号码'
        },
        component: () => import('../pages/winCode.vue')
    },
    {
        path: `${_baseRoute}/wincodeList`, 
        meta: {
            title: '最近开奖'
        },
        component: () => import('../pages/subIndex/bonusList.vue')
    },
    {
        path: `${_baseRoute}/LRecard`, 
        meta: {
            title: '投注记录',
            keepAlive: true // keep-alive不能用了
        },
        component: () => import('../pages/submine/lotteryRecard.vue')
    },
    {
        path: `${_baseRoute}/LDetail`, 
        meta: {
            title: '投注详情'
        },
        component: () => import('../pages/submine/lotteryDetail.vue')
    },
    {
        path: `${_baseRoute}/bankCards`, 
        meta: {
            title: '我的银行卡'
        },
        component: () => import('../pages/submine/bankCards.vue')
    },
    {
        path: `${_baseRoute}/addBankCard`, 
        meta: {
            title: '添加银行卡'
        },
        component: () => import('../pages/submine/addBankCard.vue')
    },
    {
        path: `${_baseRoute}/betSuccess`, 
        meta: {
            title: '投注成功',
            hiddenBack: true
        },
        component: () => import('../pages/betSuccess.vue')
    },
    {
        path: `${_baseRoute}/contact`, 
        meta: {
            title: '联系我们'
        },
        component: () => import('../pages/submine/contact.vue')
    },
    {
        path: `${_baseRoute}/qrcode`, 
        meta: {
            title: '充值二维码'
        },
        component: () => import('../pages/submine/QRCode.vue')
    },
    {
        path: `${_baseRoute}/complain`, 
        meta: {
            title: '电话投注使用说明'
        },
        component: () => import('../pages/subIndex/PhoneLotteryComplain.vue')
    },
    {
        path: `${_baseRoute}/newinfo`, 
        meta: {
            title: '最新资讯'
        },
        component: () => import('../pages/subIndex/newInfo.vue')
    },
    {
        path: `${_baseRoute}/newDetail`, 
        meta: {
            title: '详情'
        },
        component: () => import('../pages/subIndex/newsDetail.vue')
    },
    {
        path: `${_baseRoute}/login`,
        meta: {
             title: '登录',
            hiddenBack: true
        },
        component: () => import('../pages/login.vue')
    },
    {
        path: `${_baseRoute}/register`,
        meta: {
             title: '新用户有礼',
            hiddenBack: true
        },
        component: () => import('../pages/register.vue')
    },
    {
        path: `${_baseRoute}/newlogin`,
        meta: {
             title: '登录',
            hiddenBack: true
        },
        component: () => import('../pages/bankLogin.vue')
    },
    {
        path: `${_baseRoute}/recharge`,
        meta: {
            title: '充值'
        },
        component: () => import('../pages/recharge.vue')
    },
    {
        path: `${_baseRoute}/recharge-recard`,
        meta: {
            title: '账务明细'
        },
        component: () => import('../pages/rechargeRecard.vue')
    },
    {
        path: `${_baseRoute}/news`,
        meta: {
            title: '详情'
        },
        component: () => import('../pages/news.vue')
    },
    {
        path: `${_baseRoute}/alipay`,
        meta: {
            title: '充值'
        },
        component: () => import('../pages/aliPay.vue')
    },
    {
        path: `${_baseRoute}/wxLogin`,
        meta: {
            title: '登录',
            hiddenBack: true
        },
        component: () => import('../pages/wxLogin.vue')
    },
    {
        path: `${_baseRoute}/bonusSetting`,
        meta: {
            title: '玩法说明',
        },
        component: () => import('../pages/subIndex/bonusSetting')
    },
    {
        path: `${_baseRoute}/test`,
        meta: {
            title: '测试'
        },
        component: () => import('../pages/teak.vue')
    },
    {
        path: `${_baseRoute}/setting`,
        meta: {
            title: '设置'
        },
        component: () => import('../pages/submine/Setting.vue')
    },
    {
        path: `${_baseRoute}/logout`,
        meta: {
            title: '自助注销账户',
            hiddenBack: true
        },
        component: () => import('../pages/logout/index.vue')
    },
    {
        path: `${_baseRoute}/logoutSucess`,
        meta: {
            title: '操作结果',
            hiddenBack: true
        },
        component: () => import('../pages/logout/success.vue')
    },
    {
        path: `${_baseRoute}/operatorSetting`,
        meta: {
            title: '运营商设置'
        },
        component: () => import('../pages/submine/OperatorSetting.vue')
    },
    {
        path: `${_baseRoute}/404`,
        component: () => import('../pages/notFound.vue')
    },
    {
        path: '/:catchAll(.*)',
        redirect: `${_baseRoute}/404`
    }

]

const router = createRouter({
    history: createWebHistory(),
    base:`${_baseRoute}`,
    routes: [...routes, ...echartRoute]
})

// const whiteList = [
//     '/404', 
//     '/wincode', 
//     '/newinfo',
//     '/newDetail',
//     '/contact',
//     '/bonus',
//     '/wincodeList']

router.beforeEach((to, from, next) => {
    // let openId = getOpenId()
    // if (!openId && whiteList.indexOf(to.path) < 0) {
    //     openId = to.query.openId
    //     if (openId) {
    //         setOpenIdToCookie(openId)
    //     } else {
    //         getOpenId()
    //     }
    // }
    const openId = getOpenId(to.query.openId)
    console.log(123)
    if (openId) {
        document.title = to.meta.title || '上海福彩电话投注'
        if (to.query.p || to.path.indexOf('login') > -1) {
            next()
        } else {
            const tagID = getTagID()
            next({path: to.path, query: Object.assign(to.query, {p: tagID || '123'})})
        }
    }
})

export default router