
import 'vant/lib/toast/style'
import axios from 'axios'
import { toast, wxLogin } from '../util'

 // 创建一个独立的axios实例
 const service = axios.create({
     baseURL: '',
     headers: {
         post: {
             "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
         },
         H5Mark: true
     },
     timeout: 60000, 
     withCredentials: true
  })

   // 请求拦截
 service.interceptors.request.use(config => {

    if (config.url.indexOf('member_operate/payment/alipayRequest.htm')>-1 || config.url.indexOf('member_operate/saveUserOperationLogH5.htm') > -1) {
        config.headers.H5Mark = 'AilPay'
    } else {
        config.headers.H5Mark = true
    }
    return config
})

// 返回拦截
service.interceptors.response.use((response) => {
    const res = response
    let {code, message, msg } = res.data
    switch(code) {
        case 1001:
            wxLogin()
            break
        case '0000':
        case '6012':
        case '6013':
        case '6016':
        case '6113':
        case  200:
        break
        case 0:
            break
        case '9999':
            toast(message|| msg)
            break
        default:
            if(message || msg) {
                toast(message || msg)
            }
    }
    return res.data
}, () => {
    console.error("网络请求异常, 请稍后重试！")
})

export { service, axios}