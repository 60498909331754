export const serviceInfo = [
        {title: '一、总则', content: '<b>“福彩乐行”公众号（以下简称“本公众号”）属于上海市福利彩票发行中心（以下简称“市福彩中心”）所有。在本公众号合法登记注册的用户为本公众号用户。用户须遵守本服务协议的规定，方有权利享受本公众号提供的相关服务。用户使用本公众号服务即代表已认同本服务协议的约定，使用前请认真阅读，特别是其中加粗部分的内容。</b>'},
        {title: '二、电话投注一般规定', content: '<p>1.上海福彩电话销售是经批准同意，本地区用户利用固定电话、移动电话通过短信、语音等方式（以下简称“电话投注”）购买电脑型福利彩票的销售业务。</p>'
    +'<p>2.采用电话投注方式购买的彩票均为经国家财政部批准发行的彩票品种，任一彩种的游戏规则以发行机构所公布的官方规则为准。</p>'
    +'<p>3.上海福彩电话销售的彩票游戏为：双色球、<b>3D</b>、七乐彩、<b>15</b>选<b>5</b>、东方<b>6+1</b>、天天彩选<b>4</b>。</p>'
    +'<p>4.用户采用电话投注方式购买彩票，应当在本公众号开设投注账户。具体详见第三条“用户信息”。</p>'
    +'<p>5.根据国家规定，<b>禁止未成年人开设投注账户和兑奖。</b></p>'
    +'<p>6.用户采用电话投注方式购买彩票后，投注信息经电话投注系统受理，彩票销售系统确认，由电话投注系统向用户发送购买成功或者未成功信息。其中，电话投注系统是指：针对福利彩票组织体系结构和销售模式自主研发的软件系统和系统集成方案。彩票销售系统是指：彩票发行中心为开展彩票的销售、兑奖等业务，进行数据统计、管理，开奖、兑奖管理以及彩票资金管理等的系统。</p>'
    +'<p>7.<b>电话投注的截止时间会提前于彩票销售系统的彩票销售截止时间，</b>以作为彩票订单处理的冗余时间，具体提前量见本公众号短信辅助选号页面不同彩票品种当期投注截止时间。</p>'
    +'<p>8.<b>上海市电话销售福利彩票实行限时、限额管理。 彩票销售时间规定为上午8点钟到次日凌晨3点钟；限额管理规定为电脑彩票每个账户每天限额500元。</b></p>'
    // +'<p>（1）上海福彩电话销售彩票业务销售时间为上午8点钟到次日凌晨3点钟。</p>'
    // +'<p>（2）单笔彩票的投注注数不得超过<b>10000</b>注，即单笔彩票的投注金额不得超过<b>2</b>万元。</p>'
    // +'<p>（3）设置多倍投注的，每注彩票的投注倍数为<b>2</b>到<b>50</b>倍。</p>'
    // +'<p>（4）每个账户单一彩票游戏投注金额每日不得超过<b>500</b>元。每个账户游戏品种（双色球、<b>3D</b>、七乐彩、天天彩选<b>4</b>、<b>15</b>选<b>5</b>、东方<b>6+1</b>）每日累计投注金额不得超过<b>500</b>元。</p>'
},
        {title: '三、用户信息', content: '<p>（一）用户信息提供</p>'
        +'<p>用户采用电话投注方式购买彩票，应当在本公众号开设投注账户。投注账户注册信息包括用户姓名、有效身份证件号码、银行借记卡账号、注册电话号码、归属行政区域等。用户提供的银行借记卡账户、注册电话号码应当与投注账户的个人有关信息一致。每个有效身份证件仅限注册一个电话销售彩票投注账户。投注账户仅限用户本人使用，用户应当保管好投注账户、密码等信息。</p>'
        +'<p>对因用户自身原因而造成的不能充值、不能兑奖、不能提现等情况，市福彩中心不负责任。如果用户提供的资料包含有不真实的信息，市福彩中心保留终止该用户继续使用服务资格的权利。</p>'
        +'<p>（二）用户信息隐私</p>'
        +'<p>市福彩中心承诺对用户注册之隐私信息绝对保密。未经用户授权或同意，市福彩中心不会将用户信息透露给第三方或用于处理上海福彩电话投注所属业务以外的其他活动，涉及相关法律事件除外。</p>'
        +'<p>（三）用户信息安全</p>'
        +'<p>市福彩中心对用户注册信息提供最大限度的安全保障。同时，用户务必对其“登录密码”及其它个人账户信息自行保密，防止被盗用或篡改。用户如发现个人信息被盗用篡改等情况，请立即拨打客服电话：<b>4000705899</b>或发送情况说明邮件至邮箱：<b>2429641159@qq.com，</b>提供相应个人资料申请冻结账户，同时向公安机关报案，但在市福彩中心冻结账户之前所产生的一切损失由用户自行承担。</p>'
        +'<p></p>'
    },
    {
        title: '四、服务内容',
        content: '<p class="bold">1.本公众号仅为合法注册用户提供上海福利彩票电话投注服务。</p>'
        +'<p class="bold">2.辅助选号服务</p>'
        +'<p>本公众号为用户提供电脑型福利彩票游戏品种（双色球、<b>3D</b>、七乐彩、天天彩选<b>4</b>、<b>15</b>选<b>5</b>、东方<b>6+1</b>）辅助选号服务。用户可通过点击本公众号“短信选号”板块进行选号，选号确认后生成短信投注指令。用户可复制短信投注指令进行电话投注，也可自行编写短信投注指令进行电话投注。</p>'
        +'<p class="bold">3.资金结算服务</p>'
        +'<p>（1）用户发送短信投注指令至电话投注短信特服号“106388106388”等进行短信投注，投注成功的彩票款将从用户账户资金中扣除。</p>'
        +'<p>（2）用户可通过银联（支付宝、微信）等支付方式为个人“预存款账户”充值（单笔最低充值<b>10</b>元），个人预存款账户资金仅用于支付电话投注彩票款，在注销用户账户前不可提现。</p>'
        +'<p>（3）<b>当用户“预存款账户”资金余额不足时，将自动使用“奖金账户”中的资金支付彩票投注款。</b></p>'
        +'<p>（4）用户可以随时进行资金余额核查，本公众号提供用户资金账户查询明细。</p>'
        +'<p>（5）小额奖金兑奖方式：<b>10000</b>元（含）以下中奖奖金（单笔单注）直接返入中奖用户在本公众号上的“奖金账户”中。用户可以通过提交“提现”申请，将“奖金账户”中的余额转出到个人银行卡。本公众号仅为提交了“提现”申请的用户办理资金转出手续。申请“提现”时请务必确认本人身份信息和银行卡信息一致。本公众号通过审核用户填写的提现信息后，<b>将在收到“提现”申请后1-3个工作日内完成资金转出。</b>提现至银行账户的实际到账时间以银行公布的时间为准。</p>'
        +'<p>（6）大额奖金兑奖方式：超过<b>10000</b>元的大奖奖金（单笔单注）本公众号将通知中奖用户携带本人有效身份证件至上海市黄浦区四川中路<b>321</b>号“上海市福彩中心兑奖处”兑奖。</p>'
        +'<p>（7）用户个人“预存款账户”中的未投注资金和“奖金账户”中的中奖资金不计算利息。</p>'
        +'<p>（8）用户每天申请提现资金最低金额<b>5</b>元，最高金额<b>20000</b>元；每天只可申请提现一次，超过部分请分多次申请提取。提现不收取任何手续费。</p>'
        +'<p>4.本公众号不收取任何形式的服务费。</p>'
    },
    {
        title: '五、双方权利',
        content: '<p class="bold">（一）用户享有的权利</p>'
        +'<p>1. 用户有权对个人注册资料进行查询、一定范围内的补充和修改。为确保用户资金安全，<b>所有注册使用的“用户名”“真实姓名”“身份证号码”一经确认即不得自行更改，如需更改，须按要求提交相关证明证件，由市福彩中心审核通过后更改。</b></p>'
        +'<p>2. 用户可免费使用上海福彩电话投注提供的查询功能或其它允许用户使用的功能对个人账户进行管理。</p>'
        +'<p>3. <b>用户如需注销账户可通过向本公众号回复“销户”，点击之后由本公众号发送的“自助销户”链接，根据“自助销户”的流程及要求提交销户申请，审核通过后方可销户。市福彩中心自收到申请日起10个工作日内处理完毕。</b></p>'
        +'<p class="bold">（二）市福彩中心享有的权利</p>'
        +'<p class="bold">1. 市福彩中心有权以电子邮件、短信等方式通知用户终止服务，自通知发出之日起15个自然日内服务终止。市福彩中心将在终止服务后10个工作日内将用户账户内的余额以用户认可/预留信息的方式退还予用户。</p>'
        +'<p><b>2. 市福彩中心有权清退</b>已连续<b>60</b>个月无投注行为<b>的用户。</b></p>'
        +'<p>（1）针对已连续<b>60</b>个月无投注行为，账户内有资金的用户，市福彩中心有权以电子邮件、短信等方式通知用户账户情况，如自通知发出之日起15个自然日内用户无新投注行为，则视为用户单方面终止服务。市福彩中心将在终止服务后10个工作日内将用户账户内的余额以用户认可/预留信息的方式退还予用户，并注销账户。</p>'
        +'<p>（2）针对已连续<b>60</b>个月无投注行为，账户内资金为0的用户，市福彩中心有权以电子邮件、短信等方式通知用户账户情况，如自通知发出之日起15个自然日内用户无新投注行为，则视为用户单方面终止服务，将由市福彩中心注销账户。</p>'
        +'<p></p>'
        +'<p></p>'
    },
    {
        title: '六、双方责任',
        content: '<p class="bold">（一）用户责任</p>'
        +'<p> 1. 用户不得利用<b>本公众号</b>危害国家安全、泄露国家秘密，不得侵犯国家、集体的和第三人的合法权益，不得利用<b>本公众号</b>制作、复制和传播下列信息：</p>'
        +'<p>（一）煽动抗拒、破坏宪法和法律、行政法规实施的；</p>'
        +'<p>（二）煽动颠覆国家政权，推翻社会主义制度的；</p>'
        +'<p>（三）煽动分裂国家、破坏国家统一的；</p>'
        +'<p>（四）煽动民族仇恨、民族歧视，破坏民族团结的；</p>'
        +'<p>（五）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</p>'
        +'<p>（六）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</p>'
        +'<p>（七）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</p>'
        +'<p>（八）损害国家机关信誉的；</p>'
        +'<p>（九）其他违反宪法和法律行政法规的；</p>'
        +'<p>（十）进行商业广告行为的。</p>'
        +'<p><b>2.市福彩中心</b>声明，用户的系统记录有可能作为用户违反法律的证据。</p>'
        +'<p class="bold">（二）市福彩中心责任</p>'
        +'<p>1.市福彩中心应当妥善保管用户投注账户信息，并对用户个人信息进行保密。</p>'
        +'<p>2.<b>用户理解并同意，因本协议产生的任何纠纷，市福彩中心承担的责任仅限于向用户返还受影响彩票所对应的投注款项。</b></p>'
        +'<p>3.发生下列情况时市<b>福彩中心</b>不予承担任何法律责任：</p>'
        +'<p>（1）由于用户将密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露，以及由此产生的纠纷。</p>'
        +'<p>（2）任何由于黑客攻击、计算机病毒侵入或发作、网络供应商原因、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料或委托投注指令泄露、丢失、被盗用、被篡改或网络线路中断等，以及由此产生的纠纷。</p>'
        +'<p>（3）由于与<b>本公众号</b>链接的其它网站所造成之个人资料泄露及由此而导致的任何争议和后果。</p>'
        +'<p>（4）<b>市福彩中心</b>定期检查，或更新软硬件而造成的服务中断；突发性的软硬件设备与电子通信设备故障产生的纠纷。</p>'
        +'<p>（5）其他非<b>市福彩中心</b>自身原因造成的未能成功投注、兑奖等，如国家法律法规、规章要求或彩票发行中心上级主管部门要求取消或暂停前述全部或部分服务的。</p>'
        +'<p>（6）<b>因包括但不限于断电、通讯故障、延时、彩票销售系统原因、超过当期投注截止期、未在电话销售彩票业务销售时间内投注、未成功付款、限号限额等原因，造成投注失败的，不会扣除用户任何费用；且本公众号对投注失败不承担任何责任。</b></p>'
    },
    {
        title: '七、有关争端的处理 ',
        content: '<p>如使用本公众号出现纠纷，用户同意将纠纷交由市福彩中心所在地人民法院管辖。</p>'
    },
    {
        title: '八、服务协议的修改和服务修订',
        content: '<p>市福彩中心有权对本规则进行调整或补充，若用户继续使用本公众号服务，则须阅读并接受该等调整或补充，如不同意，则用户无法继续使用本公众号服务。</p>'
    },
]