import { pourNumberCount, savefc3D1D, savefc3D2D, getRepeatValueAndCount } from '../util'

// 双色球复式/胆拖注数计算
function ssqCalcPour(mode) {
  const {type, redArr, blueArr} = getCommonData(mode)
  // const chooseNum = mode.chooseNum
  const red = pourNumberCount(redArr.length, 6), blue = blueArr.length
  if (type == 'multiply') {
    const canPour = red > 0 && blue > 0
    return canPour ? red * blue : 0
  } else if (type == 'courageDrag') {
    const ballLines = mode.balls
    const courageBall_L = ballLines[0].showBalls.filter(ele => ele.isChoosed)?.length || 0
    const dragBall_L = ballLines[1].showBalls.filter(ele => ele.isChoosed)?.length || 0
    const blueBall_L = ballLines[2].showBalls.filter(ele => ele.isChoosed)?.length || 0
    const num = pourNumberCount(dragBall_L, 6 - courageBall_L)*blueBall_L
    return dragBall_L+courageBall_L < 7 ? 0 : num
  }
}

// 15选5复式投注计算
function fifteenMutiPours(mode) {
  const {redArr} = getCommonData(mode)
  const num = pourNumberCount(redArr.length, mode.chooseNum)
  return num < 1 ? 0 : num
}

// 获取公共数据
function getCommonData(mode) {
  const ballArr = mode.balls,
        type = mode.type
  let selected = 0, minOptionsLen = 0, blueArr = [], redArr = []
  ballArr.forEach((ballLine) => {
    minOptionsLen += ballLine.min
    ballLine.showBalls.forEach((ball) => {
      if (ball.isChoosed) {
        selected += 1
        if (ballLine.color == 'red') {
          redArr.push(ball.value)
        } else {
          blueArr.push(ball.value)
        }
      }
    })
  })
  return {
    type,
    selected,
    minOptionsLen,
    redArr,
    blueArr
  }
}

// 通用注数计算
function commonPourCalc(mode) {
  const {selected, minOptionsLen} = getCommonData(mode)
  return (selected > minOptionsLen || selected == minOptionsLen) ? 1 : 0
}

// 福彩3D注数计算
function fc3DPour(mode) {
  const {type, selected, minOptionsLen, redArr} = getCommonData(mode)
  console.log(456)
  if (type == 'single') {
    return selected == minOptionsLen ? 1 : 0
  } else if (type == "zx3DGroup3") {
    // return selected == minOptionsLen ? 2 : 0
    // return mode?.betNumberArr?.length || (selected == minOptionsLen ? 2 : 0)
    return selected == minOptionsLen ? 2 : 0
  } else if (type == "zxhz") { // 组选和值
    const result = [1,3,6,10,15,21,28,36,45,55,63,69,73,75,75,73,69,63,55,45,36,28,21,15,10,6,3,1]
    let index = mode.balls[0].showBalls.filter(ele => ele.isChoosed)[0]
    index = index && index.value
    return result[index] || 0
  }else if(type == '1D') {
    return savefc3D1D(mode).length
  } else if (type == '2D') {
    return savefc3D2D(mode).length
  } else if(type == 'bx3') {
    const {countMap} = getRepeatValueAndCount(redArr)
    return (countMap.has(2) && redArr.length == 3) ? 1 : 0
  } else {
    return 9
  }
}

// 七乐彩注数计算
function sevenLC(mode) {
  const {type, selected, minOptionsLen} = getCommonData(mode)
  if (type == 'multiply') {
    return selected == minOptionsLen ? 1 : pourNumberCount(selected, 7)
  } else {
    return (selected == minOptionsLen || selected > minOptionsLen) ? 1 : 0
  }
}


export function  calcPour(mode, gameId) {
    gameId = parseInt(gameId)
    if (!mode) {
      return 0
    }
    if (mode?.type == 'single') {
      return commonPourCalc(mode)
    } else {
      switch(gameId) {
        case 11:
            return ssqCalcPour(mode)
        case 51: 
            return fc3DPour(mode)
        case 24:
            return sevenLC(mode)
        case 23:
            return fifteenMutiPours(mode)
        default:
            return commonPourCalc(mode)
      }
    }
}