<template>
 <div class="home">
    <div v-show="show && showEvent($route.meta?.hiddenBack)">
      <NavBar/>
    </div>
    <router-view v-slot="{Component}">
      <keep-alive>
        <component :is="Component" :key="$route.name"  v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <component :is="Component"  :key="$route.name" v-if="!$route.meta.keepAlive"/>
    </router-view>
 </div>
</template>

<script>
import { onMounted, provide, reactive, toRefs } from '@vue/runtime-core'
import { loginData, chooseNumber } from './store'
import { refreshData, wxSdkConfig } from './utils/util'
import wx from 'weixin-js-sdk'
export default {
  name: 'App',
  setup() {
    provide('loginStatus', loginData()),
    provide('chooseNumberInfo', chooseNumber())
    refreshData()
    const state = reactive({
      show: false
    })
    wxSdkConfig()
    onMounted(() => {
      setTimeout(() => {
                state.show = true
                console.log('ddd')
                wx.ready(function() {
                  wx.updateAppMessageShareData({
                    title: document.title, // 分享标题
                    desc: '扶老 助残 救孤 济贫', // 分享描述
                    link: window.location.href.replace('openId', 'o'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'https://www.81666.net/page/dhsm/images/fclx_icon.png', // 分享图标
                    success: function () {// 设置成功
                      console.log('ccc')
                    }
                  })
                })
            }, 1000)
    })
    function showEvent(hidden) {
        console.log(hidden)
        return hidden ? false : true
    }
    return {
      ...toRefs(state),
      showEvent
    }
  }
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
}
.home_content {
  padding-bottom: 40px;
}
</style>
