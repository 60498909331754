// 银行多期介绍
export const bankMultiperiodIntro = [
    {
        title: '什么是银行多期？',
        intro: ['银行多期是上海福彩与上海地区银行合作的一款特色福利彩票产品。银行多期“双色球”一年销售', {text: '153期', color: 'red'}, '共', {text: '306元', color: 'red'},'。一年有', {text: '153次', color: 'red'},'中奖的机会。'],
        detail: [
            '“双色球”一年销售', 
            {text: '153期', color: 'red'}, 
            '一周', {text: '3次开奖', color: 'red'},
            '，每注彩票',
            {text: '2元', color: 'red'},
            '购买一年需',
            {text: '306元', color: 'red'}, 
            '。用户只需购买一次，即可完成一年期“双色球”的投注，',
            '一年有', 
            {text: '153次', color: 'red'},
            '中大奖的机会。每天只要',
            {text: '0.83元', color: 'red'},'，银行多期双色球购买成功后，于次日生效。'
        ]
    },
    
    {
        title: '哪些银行可以办理？',
        intro: [
            '上海地区的', 
            {text: '工商银行', color: 'red'},
            '和',
            {text: '农业银行', color: 'red'}, 
            '均可办理此业务，工行可在网银和网点柜面办理，农行可在网点柜面办理。'],
            detail: [
                '上海地区的', 
                {text: '工商银行', color: 'red'}, 
                '和',
                {text: '农业银行', color: 'red'}, 
                '均可以办理，工行可在网银和柜面办理，农行可在网点柜面办理。<br>',
                {text: '工行银行多期', color: 'red'},
               ':双色球可机选或自选，机选为每期随机生成投注号码，随机投注号码每期会以短信方式（每周三次发送机选号码）给用户，上海本地手机号可收到短信，外地手机号收不到短信;','自选为用户在办理成功时仅发送一次通知短信。<br>',
               {text: '农行银行多期', color: 'red'},
               ':双色球可机选或自选，机选为随机生成一注，一年153期不变号，仅发一次机选号码短信给用户。自选为用户在办理成功时仅发送一次通知短信。上海本地手机号可收到短信，外地手机号收不到短信。',
               {text: '银行多期产品，订单结束后，系统将默认为您自动续投。', color: 'red'}
            ]
    },
    
    // {
    //     title: '如何查询多期记录？',
    //     intro: ['可以通过', {text: 'www.306dt.com', color: 'red'}, '查询。'],
    //     detail: [
    //         '可以通过', 
    //         {text: 'www.306dt.com', color: 'red'},
    //         '查询,查询最多显示',
    //          {text: '近30期', color: 'red'}
    //         ]
    // }
]