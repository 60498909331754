import { 
    banlance, 
    getWinNumber, 
    balanceAndBonus, 
    userOperation, 
    loginByOpenId,
    getProvince, 
    getWxConfig,
    getNewsList} from '../api'
import { gameIDMap, machineChooseList, storeIdAuthor } from '../../common-data'
import { isProduct, isH5, isCheckUserName, isCheckAddress } from './config'
import router from '../../route/router.js'
import { Toast } from 'vant'
import dayjs from 'dayjs'
import wx from 'weixin-js-sdk'

const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

console.log(process.env.NODE_ENV)

// export const appId = 'wxb4ba108065dc4c66' // 测试
export const appId = isProduct ? 'wx4be469ce0b176ef1' : 'wxc985e1b85ec64f3b' // 正式:测试


// export const domain = 'http://teltest.szhelper.com/' // 测试
export const domain = isProduct ? 'https://wechat.81666.net/' : 'http://teltest.szhelper.com/' // 正式:测试

Toast.allowMultiple()

let userInfo

const dayWeek = [
    [0, '日'],
    [1, '一'],
    [2, '二'],
    [3, '三'],
    [4, '四'],
    [5, '五'],
    [6, '六'],
]

const dayWeekMap = new Map()
dayWeek.forEach(ele => {
    dayWeekMap.set(ele[0], ele[1])
})

// 获取玩法名字
export function getGameName(gameId) {
    return gameIDMap.get(gameId).name
}

// 获取起始日期
// day: D, month: M
export function stardAndEndTime(value) {
    let end = dayjs().toJSON()
    let start = dayjs().subtract(value[0], value[1]).toJSON()
    return {
        start, end
    }
}

// 格式化日期

export function formatTime(time = new Date(), format= 'YYYY-MM-DD HH:mm') {
    return dayjs(time).format(format)
}

// 开奖号码时间
export function openBonusTime(time = new Date(), format= 'YYYY-MM-DD HH:mm', showWeek = true) {
    // console.log(time)
    let _time = dayjs(time).format(format)
    let week = dayjs(time).day()
    return `${_time}${ showWeek ? `周${dayWeekMap.get(week)}` : ''}`
}

// 格式化开奖号码数组格式
export async function formatWinNumber (len = 6, _numberArr=[]) {
    try {
        const numberArr = _numberArr.length > 0 ? _numberArr : await loadWinNumber(len)
        const _tempNumberArr = []
        numberArr.forEach((ele) => {
            console.log(ele.gameId)
            const ball = ele.winningCode.split('#')
            let blueBall = ball[1]&&ball[1].split(',') || []
            if (ele.gameId == 61) {
                blueBall = blueBall.map(_ele => valueMap.get(_ele) || _ele)
            }
            _tempNumberArr.push({
                name: gameIDMap.get(ele.gameId).name,
                url: gameIDMap.get(ele.gameId).icon,
                issueName: ele.issueName,
                gameId: ele.gameId,
                redBall: ball[0].split(','),
                blueBall: blueBall,
                prizeDate: ele.prizeDate
            })
           
        })
        return _tempNumberArr
    } catch(e) {
        console.error(e)
    }
}

// 获取会员店隐藏事件
export function getStoreIdHiddenEvent(storeId) {
    const index =  storeIdAuthor.findIndex((ele, aindex) => {
        if (isArray(ele.storeId)) {
            const _index = ele.storeId.indexOf(storeId)
            return _index > -1 ? aindex : storeIdAuthor.length - 1
        } else {
            return ele.storeId == storeId
        }
        // ele.storeId == storeId || ele.storeId.indexOf(innerId => innerId == storeId)
    })
    return storeIdAuthor[index].hiddenEvents
}

// 是否能充值
export async function canHandleEvent(eventName) {
    try {
        const userInfo = await getUserInfo()
        const storeId = userInfo.storeId
        const hiddenEvent = getStoreIdHiddenEvent(storeId)
        return hiddenEvent.indexOf(eventName) == -1
    } catch(e) {
        console.error(e)
    }
}

// 是否能提现
export async function canWithdraw() {
    return await canHandleEvent('drawback')
}


// 返回上一页
export function goBack(rollback = 1) {
    console.log(router)
    router.go(-rollback)
}

// 获取开奖号码
export async function loadWinNumber(len) {
    try {
        const result = await getWinNumber()
        const data = result && result.data
        if (result.code == 0 && data && data.length > 0) {
            return data.slice(0, len)
        }
        return []
    } catch(e) {
        console.error(e)
    }
}

// 页面跳转
export function jumpTo(url) {
    router.push(`${baseRoute()}${url}`)
}

// 页面替换
export function replaceTo(url) {
    router.replace(`${baseRoute()}${url}`)
}

// 修改投注列表状态
export function changeLotteryRecardStatus(status = false) {
    console.log(status)
    const route = getCurrentRoute()
    const _route = route.getRoutes().filter(ele => ele.path.indexOf('LRecard') != -1)
    _route[0].meta.keepAlive = status
}

// 获取当前路由
export function getRoute() {
    return router.currentRoute.value.path
}

// 获取当前路径
export function getCurrentRoute() {
    return router
}

// 获取查询字符串
export function getQueryStr(id) {
    const route = router.currentRoute.value
    const strObj = route.query || route.params || {}
    return strObj[id]
}

// set session isHome
export function setIsHome(value) {
    sessionStorage.setItem('isHome', value)
}

// get session isHome
export function getIsHome() {
    return sessionStorage.getItem('isHome')
}

// 登陆
export function login() {
    jumpTo('/login')
}
// toast message
const _Toast = Toast
export function toast(message, duration=1800) {
    _Toast({message: message, duration: duration})
}

// 复制
export function copyText(text) {
    const dom = document.createElement('input')
    dom.value = text 
    document.body.appendChild(dom)
    dom.select()
    document.execCommand('copy')
    toast('复制成功！')
    document.body.removeChild(dom)
}

// 设置modeIndex
export function setModeIndex(index) {
    sessionStorage.setItem('modeIndex', index)
}

// 获取modeIndex
export function getModeIndex() {
    return sessionStorage.getItem('modeIndex')
}

// 判断是否是开发环境
export function isDev() {
    return process.env.NODE_ENV == 'development'
}

// 设置用户名
export function setUserName(name) {
    sessionStorage.setItem('userName', name)
}

// 获取用户名
export function getUserName() {
    return sessionStorage.getItem('userName')
}

// 获取基础路由
export function baseRoute() {
    // return ''
    // return isDev() ? '' : '/weixin'
    return ''
}

// 获取http地址
export function getBaseApi() {
    // return window.httpHref
    // return 'http://192.168.0.174:18028'
    // return 'https://www.81666.net'
    // return isDev() ? '/api' : 'http://192.168.0.174:18028'
    return '/api'
}

// 判断是否登陆
export async function checkIsLogin () {
    try {
        const result = await banlance()
        if (result.code == 0) {
            return true
        } else {
            return false
        }
    } catch(e) {
        console.error(e)
    }
}

 /**
    * 检查设备
*/
const u=navigator.userAgent

// 安卓环境
export function inAndroid () {
    return u.indexOf('Android')>-1||u.indexOf('Adr')>-1
}

// ISO环境
export function inIos() {
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

// 支付宝环境
export function inAliPay() {
    return u.match(/AlipayClient/i) == "alipayclient"
}

// 微信环境
export function inWeChat() {
    return u.match(/MicroMessenger/i) == "micromessenger"
}
const phone = '106388106388'
// 发送信息到特服号
export async function sendMessage(content, money=2) {
    if (money > 20000) {
        toast('单笔投注不能超过2万块，请修改后重试')
        return
    }
    const flag = inAndroid() ? '?' : '&'
    console.log(content, money)
    const sendUrl = `sms:${phone}${flag}body=${encodeURIComponent(content+'LX'+'#'+genSoleCode())}`
    console.log(sendUrl)
    // const userInfo = await getUserInfo()
    // userOperationEvent(5, userInfo.mobile, {smscontent: content, amount: money})
    window.location.href = sendUrl
}

 // 计算注数
 export function pourNumberCount(total, select) {
    let nA = total, nB = select, cA = 1, cB = 1 
    for (; nB >=1; nA--, nB--) {
        cA = cA * nA 
        cB = cB * nB
    }
    return cA / cB
}

// 睡眠
export function sleep(time = 1000) {
    return new Promise((res) => {
        setTimeout(() => {
            res(true)
        }, time);
    })
}

// 深拷贝
export function deepClone(data) {
    return JSON.parse(JSON.stringify(data))
}

const labelToValue = [
    ['鼠','01'],
    ['牛','02'],
    ['虎','03'],
    ['兔','04'],
    ['龙','05'],
    ['蛇','06'],
    ['马','07'],
    ['羊','08'],
    ['猴','09'],
    ['鸡','10'],
    ['狗','11'],
    ['猪','12'],
]
export const labelMap = new Map(), valueMap = new Map()
labelToValue.forEach(ele => {
    labelMap.set(ele[0], ele[1])
    valueMap.set(ele[1], ele[0])
})

const textAndValue = [
    ['奇', 5],
    ['偶', 4],
    ['大', 2],
    ['小', 1]
]
export const textToValueMap = new Map(), valueToTextMap = new Map()
textAndValue.forEach(ele => {
    textToValueMap.set(ele[0], ele[1])
    valueToTextMap.set(ele[1], ele[0])
})

// 生成投注内容
export function genLotteryInfo(mode) {
    let lotteryArr = ''
    let redArr = '', blueArr = ''
    let ballsLineArr = mode.betNumberArr, ballsLineArrIndex = mode.betNumberIndexArr
    const {mixSplit} = getNumberArrInfo(mode)
    ballsLineArr.forEach((ballsLine, ballsLineIndex) => {
        if(mode.type == 'courageDrag') {
            const courageBall_L = ballsLineArrIndex[ballsLineIndex]?.[0]?.length
            ballsLine.redBalls.splice(courageBall_L, 0, '#')
        }
        ballsLine.redBalls && ballsLine.redBalls.forEach(ele => {
            redArr += textToValueMap.has(ele) ? textToValueMap.get(ele) : ele
        })
        ballsLine.blueBalls && ballsLine.blueBalls.forEach(ele => {
            blueArr += labelMap.get(ele) ? labelMap.get(ele) : ele
        })
        
        lotteryArr += `#${redArr}${mixSplit ? '#' : ''}${blueArr}`
        redArr = ''
        blueArr = ''
    })
    return lotteryArr
}

// 判断是否为数组
export function isArray(arr) {
    return Array.isArray(arr)
}

// 获取数组长度
function getBetArrLen(betArr) {
    return betArr?.redBalls?.length + betArr?.blueBalls?.length
}

// 获取数组长度
function getArrLen(betArr) {
    return betArr?.redBalls?.length + (betArr?.blueBalls?.length || 0)
}

// 是否为单一玩法
function isSingleType(betArr) {
    let isSingle = true
    if (getBetArrLen(betArr) == 1) {
      return isSingle
    }
    for(let i = 0; i < betArr?.length-1; i++) {
      const ele = betArr[i], ele1 = betArr[i+1]
      if (getArrLen(ele) != getArrLen(ele1)) {
        isSingle = false
        break
      }
    }
    return isSingle
}
// isMixType 类型是否为混合型
// isMixture 是否包含多种玩法
// isMultiPours 是否可以多注
// isMultiTimes 是否可以多倍
// mixIndex 混合类型索引
export function getNumberArrInfo(mode) {
    const {orderNumber, typeTexts, multiPours, multiTimes, autoLotteryNumber, split} = mode
    const betArr = mode.betNumberArr
    const isMixType = mode.typeTexts
    const isMixtrue = !isSingleType(betArr)
    let minLen = 0
    mode.balls.forEach(ele => {
        minLen += ele.min
    })
    const mixIndex = isMixType ? minLen == getBetArrLen(betArr?.[0]) ? 0 : 1 : 0
    const isMultiPours = isArray(multiPours) ? multiPours[mixIndex] : multiPours
    const isMultiTimes = isArray(multiTimes) ? multiTimes[mixIndex] : multiTimes
    const mixSplit = split ? isArray(split) ? split[mixIndex] : split : false
    const mixOrderNumber = isArray(orderNumber) ? orderNumber[mixIndex] : orderNumber
    const mixAutoLotteryNumber = isArray(autoLotteryNumber) ? autoLotteryNumber[mixIndex] : autoLotteryNumber
    const label = typeTexts ? isArray(typeTexts) ? typeTexts[mixIndex] : mode.label : mode.label
    return {
        mixIndex, isMixtrue, label, isMixType, isMultiPours, isMultiTimes, mixOrderNumber, mixAutoLotteryNumber, mixSplit
    }
}


// 生成投注短信
export function genePourNumber(mode, times) {   
    const lotteryArr = genLotteryInfo(mode)
    const {mixOrderNumber, isMultiTimes} = getNumberArrInfo(mode) 
    return mixOrderNumber+lotteryArr+`${ isMultiTimes ? '#'+ times : ''}`
}

// 排序
export function arrSort(arr) {
    return arr.sort((a, b) => {
        return a - b
    })
}

// 获得随机数组
export function getRandomArr(arrLen, size, mutualArr=[]) {
    let arr = [], i = 0
    for(; i < size; i++) {
        let num = getRandomNum(arrLen)
        num += ''
        if (arr.indexOf(num) == -1 && mutualArr.indexOf(num) == -1) {
            arr.push(num)
        } else {
            i--
        }
    }
    return arrSort(arr)
}

// 获取一个随机数字
function getRandomNum(count) {
    return Math.floor(Math.random() * count)
}

// 生成球球显示数字
function geneShowNumber(length, startNum = 0) {
    let arr = []
    for(let i = 0 ; i < length; i++) {
      let value = startNum == 0 ? i + '' :
        i < 9 ? '0' + (i + 1) : (i + 1) + ''
      arr.push({value: value , isChoosed: false})
    }
    return arr
  }

// 生成显示球球数据
function generateBalls(isNumber, length, labels, startNum) {
    let arr = []
    if (isNumber) {
     arr = geneShowNumber(length, startNum)
    } else {
      labels.forEach(ele => {
        arr.push({value: ele, isChoosed: false})
      })
    }
    return arr
  }

// 初始化界面显示球球
export function initShowBalls(mode) {
    const balls = getBallArr(mode)
    balls.forEach(ele => {
        if (!ele.showBalls) {
            ele.showBalls = generateBalls(ele.numberType, ele.length, ele.labels, mode.startNum)
        }
    })
}

// 获取球球列表
function getBallArr(mode) {
    return mode && mode.balls
}

// 判断球球数组是不是全是1
function isAllOne(balls) {
    let isTrue = true
    balls.forEach(ele => {
        isTrue = ele.min == ele.max == 1
    })
    return isTrue
}

// 获取互斥号码
function getMutualArr(allArr, mutualArrIndex) {
    const _mutualArrIndex = isArray(mutualArrIndex) ? mutualArrIndex : mutualArrIndex == void 0 ? [] : [mutualArrIndex]
    let _arr = []
    _mutualArrIndex.forEach(ele => {
        _arr.push(...allArr[ele])
    })
    return _arr
}

// 获取随机投注数组
export function betRandomArr(mode) {
    // 获取球球数组列数
    const balls = getBallArr(mode)
    // 根据球球数组列数，初始化一个与列数相同长度的数组，用来存放随机球球号码
    let randomArr = new Array(balls.length).fill([])
    // 如果当前模式有获取几列的属性
    if (mode.machineChooseLen) { // 如果该玩法模式有机选长度，则生成机选长度的随机球球号码
        // 随机号码索引
        const _arrIndex = getRandomArr(balls.length, mode.machineChooseLen)
        // 随机号码值
        _arrIndex.forEach(index => {
            // 互斥号码
            const mutuals = getMutualArr(randomArr, balls[index].mutexRow)
            randomArr[index] = getRandomArr(balls[index].length, balls[index].min, mutuals)
        })
    } else { // 否则判断该列球球最少选择几个，生成相应的随机号码
        balls.forEach((ballLine, index) => {
            // 互斥号码
            const mutuals = getMutualArr(randomArr, balls[index].mutexRow,)
            randomArr[index] = getRandomArr(ballLine.length, ballLine.min, mutuals)
        })
        // 生成重复号码
        // 获取重复号码数
        const repeatlen = mode.repeat || 0 
        // 如果无重复号码，且规则需要生成重复号码，则执行下面函数
        // 生成重复号码规则：随机获取重复号码下标，并将下标为0的值赋值给该下标
        if (isAllOne(balls) && mode.repeat != void 0) {
            const _randomArr = getRandomArr(balls[0].length, balls.length)
            if (repeatlen && repeatlen > 0) {
                // 获取重复数据下标数组，下标0为被重复数据，其它为重复数据
                const repeats = getRandomArr(randomArr.length, repeatlen)
                for (let i = 1; i < repeatlen+1; i++) {
                    _randomArr[repeats[i]] = _randomArr[repeats[0]]
                }
            }
            _randomArr.forEach((ele, index) => {
                randomArr[index] = [ele]
            })
        }
    }
    return randomArr
}

// 填充完整数字
export function fillNumber(startNum, i) {
    return startNum == 0 ? i + '' :
           i < 9 ? '0' + (i + 1) :
           (i + 1) + ''
}

// 清空球球选中状态
export function clearChoosedStatus(mode) {
    const balls = getBallArr(mode)
    balls.forEach((ballLine) => {
        ballLine?.showBalls?.forEach(ball => ball.isChoosed = false)
    })
}

// 检测输入是否有为空
export function inputIsAllFill(data) {
    for(let i = 0, l = data.length; i < l; i++) {
        let ele = data[i]
        if (ele.value == '' || ele.value == void 0) {
            if (ele.label) {
                if (ele.label?.indexOf('请') == -1) {
                    toast(`${ele.label}不能为空`)
                } else {
                    toast(`${ele.label}`)
                }
            }
            return false
        }
    }
    return true
}

// 保存选中号码索引
export function saveLotteryNumberIndexArr(mode, showBallsIndex) {
    console.log(showBallsIndex)
    // 获取玩法
    const ballLines = getBallArr(mode)
    let lineArr = []
    ballLines.forEach((ballLine) => {
        let ballArr = []
        ballLine.showBalls.forEach((ball, ballIndex) => {
            if(ball.isChoosed) {
                ballArr.push(ballIndex)
            }
        })
        lineArr.push(ballArr)
    })
    mode.betNumberIndexArr = mode.betNumberIndexArr || []
    if (showBallsIndex != void 0) {
        mode.betNumberIndexArr[showBallsIndex] = lineArr
    } else {
        mode.betNumberIndexArr.push(lineArr)
    }
}

// 获取选中的红球和篮球
export function getRedAndBlueBalls(mode) {
    const redBalls = [], blueBalls = []
    mode.balls.forEach((ballInfos) => {
        ballInfos.showBalls.forEach((ball) => {
        if (ballInfos.color == 'red' && ball.isChoosed) {
          redBalls.push(ball.value)
          } else if (ballInfos.color == 'blue' && ball.isChoosed) {
          blueBalls.push(ball.value)            
        }})
    })
    return {
        redBalls, blueBalls
    }
}

// 保存通用投注号码
export function saveCommonLotteryNumberArr(mode, showBallsIndex, pour) {
    const {redBalls, blueBalls} = getRedAndBlueBalls(mode)
    return [{
        redBalls, blueBalls, pour
    }]
}

// 保存3D-1D号码
export function savefc3D1D(mode) {
    const balls = mode.balls
    let arr = []
    balls.forEach((ele, index) => {
        const choosedEle = ele.showBalls.filter(ele => ele.isChoosed)
        choosedEle.forEach(ball => {
            arr.push({redBalls: index == 0 ? [`${ball.value}`, 'a', 'a'] : index == 1 ? ['a', `${ball.value}`, 'a'] : ['a', 'a', `${ball.value}`]})
        })
    })
    console.log(arr)
    return arr
}

// 获取数组中的重复数据及重复次数
export function getRepeatValueAndCount(arr) {
    const valueMap = new Map(), // 重复号码值
     countMap = new Map() // 重复数量
    arr.forEach((ele => {
      if (valueMap.has(ele)) {
        const value = valueMap.get(ele)
        valueMap.set(ele, value+1)
      } else {
        valueMap.set(ele, 1)
      }
    }))
    valueMap.forEach((value, key) => {
      countMap.set(value, key)
    })
    return {valueMap, countMap}
  }

// 保存3D-2D号码
export function savefc3D2D(mode) {
    const balls = mode.balls // 
    let ballLines = new Array(balls.length).fill([])
    console.log(ballLines)
    balls.forEach((ele, index) => {
        const choosedBalls = ele.showBalls.filter(ele => ele.isChoosed)
        let _arr = []
        choosedBalls.forEach(ball => _arr.push(ball.value))
        ballLines[index] = _arr
        console.log(index, ballLines[index] )
    })
    const arr = []
    ballLines[0].forEach((firstValue) => {
        ballLines[1].forEach(secValue => {
            arr.push({redBalls: [firstValue, secValue, 'a']})
        })
        ballLines[2].forEach(thirdValue => {
            arr.push({redBalls: [firstValue, 'a', thirdValue]})
        })
    })
    ballLines[1].forEach(secValue => {
        ballLines[2].forEach(thirdValue => {
            arr.push({redBalls: ['a', secValue, thirdValue]})
        })
    })
    return arr
}

// 保存3D-猜三同
export function savefc3DThreeSame() {
    return [{redBalls: ['a', 'a', 'a']}]
}

// 保存3D-拖拉机
export function savefc3DTLJ() {
    return [{redBalls: ['a', 'b', 'c']}]
}



 // 保存福彩3D组选3-单式号码
 export function savefc3DGroup3(mode) {
    let redBalls = [], blueBalls = []
    let chooseBall = mode.balls[0].showBalls.filter(ele => ele.isChoosed)
    let arr = []
    if (chooseBall.length == 2) {  
      const num1 = chooseBall[0].value, num2 = chooseBall[1].value
      redBalls.push(...[num1, num1, num2])
      arr.push({redBalls, blueBalls})
      redBalls = []
      redBalls.push(...[num2, num2, num1])
      arr.push({redBalls, blueBalls})
    }
    return arr
  }

  // 保存天天彩选4组选号码
  function saveCx4(mode) {
      let chooseBall = []
      mode.balls.forEach(ballLine => {
          ballLine.showBalls.forEach(ball => {
              if (ball.isChoosed) {
                  chooseBall.push(ball.value)
              }
          })
      })

      let redBalls = [], blueBalls = []
      const groupNumber = mode.label.match(/\d+/)[0]
      switch (groupNumber) {
        case '4': 
            redBalls.push(...[chooseBall[0], chooseBall[0], chooseBall[0], chooseBall[1]])
            break
        case '6': 
            redBalls.push(...[chooseBall[0], chooseBall[0], chooseBall[1], chooseBall[1]])
            break
        case '12':
            redBalls.push(...[chooseBall[0], chooseBall[0], chooseBall[1], chooseBall[2]])
            break
        case '24':
            redBalls.push(...[chooseBall[0], chooseBall[1], chooseBall[2], chooseBall[3]])
            break
            
      }
      return [{
          redBalls, blueBalls
      }]
  }

  // 获取投注号码
  export function getBetNumber(mode, showBallsIndex, pour) {
      const type = mode.type
      const label = mode.label
      let arr = []
      switch(type) {
        case 'zx3DGroup3':
            arr = savefc3DGroup3(mode, showBallsIndex)
            break
        case 'cx4Group':
            arr = saveCx4(mode, showBallsIndex)
            break
        case '1D':
            arr = savefc3D1D(mode, showBallsIndex)
            break
        case '2D':
            arr = savefc3D2D(mode, showBallsIndex)
            break
        default:
            arr = saveCommonLotteryNumberArr(mode, showBallsIndex, pour)
      
      }
      switch(label) {
        case '猜三同':
            arr = savefc3DThreeSame(mode, showBallsIndex)
            break
        case '拖拉机':
            arr = savefc3DTLJ(mode, showBallsIndex)
            break
        
      }
      return arr
  }



 // 保存投注号码
 function saveBetNumber(mode, showBallsIndex, pour) {
    mode.betNumberArr = mode.betNumberArr || []
    const arr = getBetNumber(mode, showBallsIndex, pour)
    if (showBallsIndex != void 0) {
        mode.betNumberArr[showBallsIndex] = arr[0]
    } else {
        mode.betNumberArr.push(...arr)
    }
 }
  // 修改球球状态
  // selectedBalls {Array}  选中球球的下标数组
  // mode {Object} 玩法模式
  export function changeBallsStatus(selectedBalls, mode) {
    // 重置球球选择状态为空
    clearChoosedStatus(mode)
    // 获取该模式下球球数组
    const balls = getBallArr(mode)
    // 如果有选择球球，把该球球状态修改为选中状态
    if (selectedBalls) {
        selectedBalls.forEach((line, index) => {
            const ballLine = balls[index].showBalls 
            line.forEach((ball) => {
              ballLine[ball].isChoosed = true
            })
        })
    } else {
        balls.forEach((ballLine) => {
            ballLine.showBalls.forEach(ball => ball.isChoosed = false)
        })
    }
  }
// 随机选择一注，保存选中数据下标
  export function geneBetNumbersIndex(mode) {
      return betRandomArr(mode)
  }


// 添加机选一注，修改显示页面为添加机选的数据，保存选中数据下标及选中数据值
  export function addMachineArr(mode, showBallsIndex) {
      initShowBalls(mode)
      changeBallsStatus(geneBetNumbersIndex(mode), mode)
      saveLotteryData(mode, showBallsIndex)
  }
 // 删除已选注数
 export function deleteNumber(index, currentMode) { 
    currentMode.betNumberArr.splice(index, 1)
    currentMode.betNumberIndexArr.splice(index, 1)
}
// 机选
  export function machineChoose(mode) {
      changeBallsStatus(geneBetNumbersIndex(mode), mode)
  }
// 保存投注号码
  export function saveLotteryData(mode, showBallsIndex, pour = 1) {
        saveLotteryNumberIndexArr(mode, showBallsIndex)
        saveBetNumber(mode, showBallsIndex, pour)
  }
  
// 判断是否登录
export async function judgeIsLogin() {
    try {
        const result = await getUserInfo(true)
        return result?.code == 0 ? true : false
    } catch(e) {
        console.error(e)
    }
}

  // 获取用户信息
  export async function getUserInfo(refresh = false) {
      try {
          if (userInfo && !refresh) {
              return userInfo
          } else {
            const result = await balanceAndBonus()
            if (result.code == 0) {
                const data = result.data
                userInfo = data.user
                userInfo.userNo = data.userNo
                userInfo.open = data.open
                userInfo.code = result.code
                userInfo.url = data.url
                setTagID(userInfo.url)
                setIsWhiteList(userInfo.open == 1) 
                return userInfo
            }
            return {}
          }
      } catch(e) {
          console.error(e)
      }
  }

  // 设置visitor的值
  export function setVisitor(value) {
      sessionStorage.setItem('visitor', value)
  }

  // 获取visitor的值
  export function getVisitor() {
      return sessionStorage.getItem('visitor') || false
  }

  // 移除visitor
  export function removeVistor() {
      return sessionStorage.removeItem('visitor')
  }

  // 设置手机号
  export function setMobile(value) {
      sessionStorage.setItem('phone', value)
  }

  // 获取手机号
  export function getMobile() {
      return sessionStorage.getItem('phone')
  }

  // 储存session
  export function setSession(name, value) {
      sessionStorage.setItem(name, value)
  }

  // 获取session
  export function getSession(name) {
      return sessionStorage.getItem(name)
  }

  // 设置多期投注tabIndex
  export function setHeadTabIndex(value) {
      return setSession('termlyTabIndex', value)
  }

  // 获取多期投注tabIndex
  export function getHeadTabIndex() {
      return getSession('termlyTabIndex')
  }

  // 设置红包session
  export function setShowRed(value) {
      setSession('redBall', value)
  }

  // 获得红包session
  export function getShowRed() {
      return getSession('redBall')
  }

  // 获取显示指引值
  export function showGuidValue() {
      return localStorage.getItem('showGuid')
  }

  // 设置指引值
  export function setGuidValue(value) {
      localStorage.setItem('showGuid', value)
  }

  // 回到顶部
  export function goTop(value = 0) {
      window.scrollTo(0, value)
    //   if (document.body.scrollTop) {
    //       document.body.scrollTop = value
    //   } else {
    //       document.documentElement.scrollTop = value
    //   }
  }

  // 获取距离顶部距离
  export function getTopValue() {
      return document.body.scrollTop || document.documentElement.scrollTop
  }

  // 是否在奖期
  export function canPour(startTime, endTime) {
      const now = new dayjs()
      const start = new dayjs(startTime)
      const end = new dayjs(endTime)
      return (start < now || start == now) && (now < end || now == end)
  }

  // 获取首页悬浮标识
  export function getSuspendFlag() {
      const value = sessionStorage.getItem('suspend')
      console.log(value)
      if (!value) {
          setSuspendFlag(true)
          return 'true'
      }
      return value
  }

  // 设置首页悬浮标识值
  export function setSuspendFlag(value) {
      sessionStorage.setItem('suspend', value)
  }

  // 格式化金额
  export function formatMoney(num) {
      const _num = parseInt(num*100)/100 
      return _num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }

  // 添加分位
  export function appendCent(num = '0') {
    //   let _num = num
      const _num = num.toString().split('.')
      return ! _num[1] ? `${_num}.00` 
             : _num[1].length == 1 ? `${_num[0]}.${_num[1]}0` 
             : _num[1].length == 2 ?  num
             : `${_num}.00`
  }

  // 函数防抖
export function debounce(fn, delay) {
    return function(args) {
        let _this = this 
        let _args = args 
        clearTimeout(fn.id) 
        fn.id = setTimeout(function() {
            fn.call(_this, _args)
        }, delay)
    }
}

// clear mode 
export function clearMode(mode, clearAll = false, modeIndex = 0) {
    if (clearAll) {
        mode.modes.forEach(ele => {
            ele.betNumberArr = []
            ele.betNumberIndexArr = []
            clearChoosedStatus(ele)
        })
    } else {
        mode.modes[0].betNumberArr = []
        mode.modes[0].betNumberIndexArr = []
        clearChoosedStatus(mode.modes[0])
    }
    mode.modeIndex = modeIndex
    setModeIndex(0)
}

// 是否为15选5
export function isSevenChooseFive(gameId) {
    const isTrue = (gameId == 23) || (gameId == '23')
    console.log(isTrue, gameId)
    return isTrue
}

// 用户行为事件
export function userOperationEvent(type, mobile, content) {
    userOperation({
        mobile: mobile,
        operationType: type,
        content: content
    })
}

let tagID = ''

// 设置标识ID
export function setTagID(id) {
    tagID = id
}

// 获取标识ID
export function getTagID() {
    return tagID
}

let isWhite = false

const insert = {label: '多期投注', route: '/termly', img: require('../../assets/imgs/icon_foot_termly.png'), img2: require('../../assets/imgs/icon_foot_termly_active.png')}
    
let arr = [
    {label: '主页', route: '/', img: require('../../assets/imgs/icon_foot_home.png'), img2: require('../../assets/imgs/icon_foot_home_selected.png')},
    {label: '短信指令', route: '/message-order', img: require('../../assets/imgs/icon_foot_open_price.png'), img2: require('../../assets/imgs/icon_foot_open_price_selected.png')},
    {label: '个人中心', route: '/mine', img: require('../../assets/imgs/icon_foot_mine.png'), img2: require('../../assets/imgs/icon_foot_mine_selected.png')}
]

let arrAll = JSON.parse(JSON.stringify(arr))
arrAll.splice(1, 0, insert)

// 设置是否为白名单
export function setIsWhiteList(value) {
    isWhite = value
}


// 获取底部tab
export function getFootTab() {
   return isWhite ? arrAll : arr
}

// 微信环境
export function inWeApp () {
    var ua = navigator.userAgent.toLowerCase()
    return ua.match(/MicroMessenger/i) == "micromessenger"
}

const apiUrl = '/api'

// 回调地址跳转
export function OpenIDReurnAddress () {
    var url = location.href
    window.location.href = apiUrl + "/app/wxmp/login.htm?next=" + encodeURIComponent(url) + `&sign=${inWeApp()? 2 : 1}`
 }

 // 获取openID
 export function getOpenId(openIdValue) {
    let openId = openIdValue ||  getOpenIdFromCookie() || getQueryStr('openId')
    console.log(openId)
    if (!openId) {
        const url = location.href
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${domain}telweb/app/wxmp/getOpenId?next=${url}?&response_type=code&scope=snsapi_base&state=STATE#wechat_redirecthttps://962333.szhelper.com`
    } else {
        setOpenIdToCookie(openId)
        return openId
    }
}

 // 获取cookie的OpenID
 export function getOpenIdFromCookie(objName = 'app_user_sso') {
    return sessionStorage.getItem(objName) || ''
 }

 // 设置cookie的OpenID
 export function setOpenIdToCookie(value) {
    // var Days = 30;
	// var exp = new Date();
	// exp.setTime(exp.getTime() + Days*24*60*60*30);
	// document.cookie = 'app_user_sso' + "="+ value
    sessionStorage.setItem('app_user_sso', value)
 }


 // 检测到未登录需要显示弹出框的页面
 const interceptRoute = [] // ['bankMulti', 'chooseNumber']
 // 微信登录事件
 export async function wxLogin() {
    try {
        if (isH5) {
            jumpTo('/login')
            return
        }
        const result = await loginByOpenId(getOpenIdFromCookie())
        if (result.code == '0000') {
            goBack(0)
        } else if(result.code == '6012') {
            if(!interceptRoute.some(ele => getRoute().indexOf(ele) > -1)) {
                jumpTo('/login')
            }
        }
    } catch(e) {
        console.error(e)
    }
 }

 // openID检测
//  export async function checkOpenId() {
//     try {
//         let openId = getOpenIdFromCookie()
//         if (openId) {
//             await wxLogin(openId)
//         } else {
//             openId = getQueryStr('openId')
//             if (!openId) {
//                 getOpenId()
//             } else {
//                 setOpenIdToCookie(openId)
//                 await wxLogin(openId)
//             }
//         }
//     } catch(e) {
//         console.error(e)
//     }
//  }

 export function hiddeName(name) {
    return name && name.replace(/.(?=.)/g, '*') || ''
 }
 export function hiddenCardNo(number) {
    return number && number.replace(/^(\d{3})\d*(\d{4})$/,"$1****$2") || ''
 }

 export function hiddeIdCard(info) {
    const len = info.length
    let str = ''
    for (let i = 0, l = len - 7; i < l; i++) {
        str+='*'
    }
    return info && info.replace(/^(.{3})(?:\d+)(.{4})$/,`$1${str}$2`)
 }

 // 银行卡检验
 export function bankNumberCheck(bankno) {
    var lastNum=bankno.substr(bankno.length-1,1);//取出最后一位（与luhm进行比较）

        var first15Num=bankno.substr(0,bankno.length-1);//前15或18位
        var newArr=new Array();
        for(var i=first15Num.length-1;i>-1;i--){    //前15或18位倒序存进数组
            newArr.push(first15Num.substr(i,1));
        }
        var arrJiShu=new Array();  //奇数位*2的积 <9
        var arrJiShu2=new Array(); //奇数位*2的积 >9

        var arrOuShu=new Array();  //偶数位数组
        for(var j=0;j<newArr.length;j++){
            if((j+1)%2==1){  //奇数位
                if(parseInt(newArr[j])*2<9){
                    arrJiShu.push(parseInt(newArr[j])*2);
                }else{
                    arrJiShu2.push(parseInt(newArr[j])*2);
                }
            }else{  //偶数位
                arrOuShu.push(newArr[j]);
            }
        }

        var jishu_child1=new Array();//奇数位*2 >9 的分割之后的数组个位数
        var jishu_child2=new Array();//奇数位*2 >9 的分割之后的数组十位数
        for(var h=0;h<arrJiShu2.length;h++){
            jishu_child1.push(parseInt(arrJiShu2[h])%10);
            jishu_child2.push(parseInt(arrJiShu2[h])/10);
        }

        var sumJiShu=0; //奇数位*2 < 9 的数组之和
        var sumOuShu=0; //偶数位数组之和
        var sumJiShuChild1=0; //奇数位*2 >9 的分割之后的数组个位数之和
        var sumJiShuChild2=0; //奇数位*2 >9 的分割之后的数组十位数之和
        var sumTotal=0;
        for(var m=0;m<arrJiShu.length;m++){
            sumJiShu=sumJiShu+parseInt(arrJiShu[m]);
        }

        for(var n=0;n<arrOuShu.length;n++){
            sumOuShu=sumOuShu+parseInt(arrOuShu[n]);
        }

        for(var p=0;p<jishu_child1.length;p++){
            sumJiShuChild1=sumJiShuChild1+parseInt(jishu_child1[p]);
            sumJiShuChild2=sumJiShuChild2+parseInt(jishu_child2[p]);
        }
        //计算总和
        sumTotal=parseInt(sumJiShu)+parseInt(sumOuShu)+parseInt(sumJiShuChild1)+parseInt(sumJiShuChild2);

        //计算Luhm值
        var k= parseInt(sumTotal)%10==0?10:parseInt(sumTotal)%10;
        var luhm= 10-k;

        if(lastNum==luhm){
            return true;
        }else{
            return false;
        }
 }

 // 设置多期显示用户指引值
 export function setShowUserGuidHidden(value) {
    localStorage.setItem('hiddenGuid', value)
 }

 // 获取多期用户指引值
 export function getShowUserGuidHidden() {
    return localStorage.getItem('hiddenGuid')
 }

 // 微信sdk初始化
 export async function wxSdkConfig() {
    try {
        const url =  window.location.href
        const result = await getWxConfig(url)
        if (result.code == 0) {
            const {signature, nonceStr, timestamp} = result.data
            wx.config({
                appId: appId,
                signature: signature, 
                url: url,
                nonceStr: nonceStr, 
                timestamp: timestamp,
                jsApiList: ['getLocation', 'updateAppMessageShareData'],
            })
        }
    } catch(e) {
        console.error(e)
    }
 }

 let addressError = ''
 // 获取gps位置
 export async function getGpsLocation() {
    console.log('getLocation')
    return new Promise((resolve) => {
        wx.getLocation({
            type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
            success: function (res) {
                resolve(res)
            },
            fail: function(res) {
                const wxError = JSON.stringify(res)
                if (wxError.errMsg) {
                    addressError = wxError.errMsg
                }
                resolve({})
            },
            cancel: function() {
                resolve({})
            }
        })
    })
}

// 获取省市区地址
export async function getProvinceData() {
    try {
        await wxSdkConfig()
        await sleep(600)
        const result = await getGpsLocation()
        const {latitude, longitude} = result
        if (latitude) {
            const provinceInfo = await getProvince({latitude, longitude})
            const userAddress = provinceInfo.result?.formatted_address
            return userAddress
        }
        return ''

    } catch(e) {
        console.error(e)
    }
}

// 获取存储的定位地址
function getStorageAddress() {
    let info = localStorage.getItem('address')
    if (info) {
        info = JSON.parse(info)
        if (info.expireTime > new Date().getTime()) {
            return info.value
        } else {
            localStorage.removeItem('address')
        }   
    }
    return ''
}

// 存储定位地址
// 保留时间 1000*60*60*4
function setStorageAddress(address, overtime = 1000*60*60*4) {
    const obj = {
        value: address,
        expireTime: new Date().getTime() + overtime
    }
    localStorage.setItem('address', JSON.stringify(obj))
}

// 判断是否为H5
export function getIgnoreAddressTag() {
    return sessionStorage.getItem('ignoreAdd')
}

export function setIgnoreAddressTag(value) {
    return sessionStorage.setItem('ignoreAdd', value)
}

// 判断是否在上海

export async function isInProvince(province = '上海市', overtime) {
    try {
        if (isH5) {
            await getUserInfo(true)
        } else if (!userInfo?.mobile) { // 未登录跳转登录
            const res = await loginByOpenId(getOpenIdFromCookie())
            if (res.code != '0000') {
                await getUserInfo(true)
                return
            }
        }
        if (getIgnoreAddressTag() || !isCheckAddress) {
            return true
        }
        let address = getStorageAddress()
        if (!address) {
            const addressTost = Toast.loading({
                forbidClick: true,
                loadingType: 'spinner'
            })
            address = await getProvinceData()
            addressTost.clear()
        }
        if (!address) {
            toast(`${addressError || '请允许获取位置'}`, 800)
            return false
        } 
        setStorageAddress(address, overtime)
        if (address?.indexOf(province) == 0) {
            return true
        } else {
            toast(`您当前位置不在${province}辖区范围内`)
            return false
        }
    } catch(e) {
        console.error(e)
    }
}

// 获取机选模式
export function getSysMachineChooseModes(gameId) {
    return machineChooseList.filter(ele => ele.id == parseInt(gameId))?.[0]?.modes || []
}


// 返回或前进到当前页面刷新数据
let isPageHide = false
export function refreshData() {
    window.addEventListener('pageshow',
    function(){
        if(isPageHide) {
            window.location.reload();
        }
    });
    window.addEventListener('pagehide',
    function(){
        isPageHide = true;
    });

}

//按千位处理金额
export function moneyFormat(money) {
    if (money !== undefined && typeof money === 'number') {
        money = Number(money)
        const arr = money.toString().split('.');
        return (
            (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') +
            (arr[1] ? '.' + arr[1] : '')
        );
    } else {
        return money;
    }
}

// 订单状态
export function recardStatusText(isFail, isWin, bonusStatus) {
    return isFail ? '彩金已退还' 
          :bonusStatus == 0 ? '待开奖'
          :bonusStatus == 1 ? '已开奖'
          :bonusStatus == 2 ? '派奖中'
          : isWin == 1 ? '已中奖' : '未中奖'
}

// 订单状态颜色
export function recardStatusColor(isFail, isWin, bonusStatus) {
    return isFail ? 'black' 
    :bonusStatus == 0 ? 'green'
    :bonusStatus == 1 ? 'red'
    :bonusStatus == 2 ? 'red'
    : isWin == 1 ? 'red' : 'gray'
}

// 判断是否只有数字和字符
export function isPasswordFormat(str) {
    const patern = /^[A-Za-z0-9]+$/
    return patern.test(str)
}

//走势图画线
export function fnLineChart(eleDots, lineColor = "#ff9916") {
    eleDots.forEach((ele, index) => {
        const eleNext = eleDots[index - 1];
        if (!eleNext) {
            return;
        }
        let eleLine = ele.querySelector("i");
        if (!eleLine) {
            eleLine = document.createElement("i");
            eleLine.className = "line";
            ele.appendChild(eleLine);
        }
        // 记录坐标
        const boundThis = ele.getBoundingClientRect();
        // 下一个点的坐标
        const boundNext = eleNext.getBoundingClientRect();
        // 计算长度和旋转角度
        const x1 = boundThis.left,
            y1 = boundThis.top;
        const x2 = boundNext.left,
            y2 = boundNext.top;
        // 长度
        const distance = Math.sqrt(
            (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1),
        );
        // 弧度
        const radius = Math.atan2(y2 - y1, x2 - x1);
        // 设置线条样式
        eleLine.style.width = distance + "px";
        eleLine.style.transform = `rotate(${radius}rad)`;
        eleLine.style.backgroundColor = lineColor
    });
}

//本地存储localStorage
export function setSessionStorage(key, value) {
    return sessionStorage.setItem(key, value)
}
//获取本地存储sessionStorage
export function getSessionStorage(key) {
    return sessionStorage.getItem(key)
}

// 存储是否显示指引
export function setIsHiddenGuid(value) {
    sessionStorage.setItem('hiddenGuid', value)
}

// 获取是否显示指引
export function getIsHiddenGuid() {
    return sessionStorage.getItem('hiddenGuid') == 'true'
}

// 前往新闻详情
export async function goNewsDetail(item) {
    const {id, flagIntoDetail, customerUrl} = item
    if (customerUrl) {
        if (customerUrl.indexOf('/chooseNumber') > -1) {
            const inProvince = await isInProvince()
            if (inProvince) {
                jumpTo(customerUrl)
            }
        } else {
            jumpTo(customerUrl)
        }
    } else if(flagIntoDetail) {
        jumpTo(`/newDetail?id=${id}`)
    }
}

// 获取新闻列表
export async function loadNewsList(params) {
    try {
        const result = await getNewsList(params)
        return result.rows || []
    } catch(e) {
        console.error(e)
    }
}

// 插入字符
export function insertChar(source, start, newChar) {
    return source.slice(0, start) + newChar + source.slice(start)
}

// 获取随机字符
export function getRandomChar() {
    return String.fromCharCode(getRandomNum(2) ? 65 : 97 + getRandomNum(25))
}

// 生成防重码
export function genSoleCode() {
    // 时间戳
    let str = formatTime(new Date, 'HHmmss')
    // 随机字符
    let i = 2
    while(i) {
        str = insertChar(str, getRandomNum(str?.length), getRandomChar())
        i--
    }
    return str
}

// 判断是否完善信息
export function isFillInfo(userInfo) {
    return isCheckUserName && (userInfo.realName == '' || userInfo.realName == void 0 || userInfo?.cardNumber?.length != 18)
}


// 判断是否投注时间
export function isLotteryTime() {
    const curTime = dayjs().format('YYYY-MM-DD')
    const curTimeStart = curTime + ' 3:00:00'
    const curTimeEnd = curTime + ' 8:00:00'
    if (dayjs().isBetween(curTimeStart, curTimeEnd)){
        toast('每日03:00:00-08:00:00暂停生成短信指令服务')
        return false
    }
    return true

}

// 判断是否超出限额
export function isOverLimit(num) {
    if (num > 500) {
        toast('单日限额500元')
        return true
    }
    return false
}


//计算指定日期距离N天后的日期
export function getDateAfterNDay(date, n) {
    const currentDate = dayjs(date);
    const futureDate = currentDate.add(n, 'day');
    return futureDate.format('YYYY年MM月DD日')
}


export * from './LNumber'
export * from './checkInfo.js'




