<template>
    <div class='common_box'>
        <div class="common_box_title" v-show="showTitle" :class="{'more-arrow': viewMore}">{{title}}</div>
        <slot></slot>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
export default {
    name: '',
    props: {
        title: {
            type: String,
            default: '提示框'
        },
        viewMore: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
         const state= reactive({
        })
        const showTitle = computed(() => {
            return props.title.length > 0
        })
        return {
            ...toRefs(state),
            showTitle
        }
    },
}
</script>
<style lang='scss' scoped>
.common_box {
    background: #fff;
    border-radius: $box-radius-small;
    margin-top: 10px;
    &_title {
        font-weight: 700;
        // border-bottom: solid $line-gray 1px;
        padding: 11px 10px 2px 10px;
        position: relative;
        &::after{
            content: '';
            background: $theme-dark;
            position: absolute;
            width: 3px;
            height: 15px;
            left: 3px;
            top: 14px;
            border-radius: $box-radius-small;
        }
    }
    .more-arrow {
        background: url('../../assets/imgs/arrow_right_gray.png') right center no-repeat;
        background-size: 8px;
    }
}
</style>