// import { createApp } from 'vue'
// import App from './App.vue'
import app from './vant'
import router from './route/router.js'
import './public/common.scss'
import './public/chart.scss'
import 'vant/lib/index.css'; //vant
// import Toaster from '@meforma/vue-toaster'
// import {Toast} from 'vant'
import { Collapse, CollapseItem, NavBar } from 'vant'
import CommonBox from './components/common-box'
import DialogBox from './components/dialog'
import NoData from './components/no-data'
import NavBars from './components/nav-bar'
// Toast.allowMultiple()
document.title = '上海福彩电话投注'
// createApp(App)
app.use(router)
.use(CommonBox)
.use(NoData)
.use(DialogBox)
.use(NavBars)
.use(Collapse)
.use(NavBar)
.use(CollapseItem)
.mount('#app')
