import {service as instance, axios} from './interceptor'
import { Toast} from 'vant'

Toast.allowMultiple()
let isLoading = false


let source = axios.CancelToken.source()

async function request( url, params, method='get', text, loading, forbid) {
    let loadingToast
    if (!isLoading && loading && !loadingToast) {
        isLoading = true
        loadingToast = Toast.loading({
            message: text,
            forbidClick: forbid,
            loadingType: 'spinner',
            duration: 20000
        })
    }
    return new Promise((resolve, reject) => {
        const isPost = method == 'post'
        instance({
            url: url,
            method: method,
            params: params,
            data: isPost ? params : {},
            cancelToken: source.token
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        }).finally(() => {
            isLoading = false
            if (loading && loadingToast) {
                loadingToast.clear()
            }
        })
    })
}

function get(url, params={},  text = '数据加载中...', loading = false, forbid = true) {
    return request(url, params, 'get', text, loading, forbid)
}

function post(url, params={}, text = '数据加载中...', loading = false, forbid = true) {
    return request(url, params, 'post', text, loading, forbid)
}

// 取消请求
function cancelRequest() {
    source.cancel()
    source = axios.CancelToken.source()
}

export {
    get, post, cancelRequest
}