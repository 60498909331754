<template>
    <div class="nav">
        <div class='nav_bar'>
        <div class="nav_bar_back" @click="back">
            <span>返回</span></div>
        <slot></slot>
    </div>
    </div>
</template>
<script>
import { goBack, replaceTo } from '../../utils/util'
export default {
    name: '',
    props: {
        title: {
            type: String,
            default: ''
        },
    },
    setup() {
        function back() {
            const hasBackPage = window.history?.length > 2
            if (hasBackPage) {
                goBack()
            } else {
                replaceTo('/')
            }
        }
        return {
            back,
        }
    }
}
</script>
<style lang='scss' scoped>
.nav {
    height: 41px;
}
.nav_bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 41px;
    line-height: 41px;
    background: $theme-dark;
    z-index: 9;
    &::after {
        content: '';
        padding-bottom: 41px;
    }
    &_back {
        position: absolute;
        margin-left: 8px;
        height: 25px;
        width: 130px;
        background: url('../../assets/imgs/arow_left_white.png') left center no-repeat;
        background-size: 20px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        color: #fff;
        display: flex;
        align-items: center;
        span {
            padding-left: 20px;
            opacity: .95;
            margin-top: 0px;
        }
    }
}
</style>