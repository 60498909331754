const simpleTitleArr = ['奖项', '中奖条件', '奖金']
const wholeTitleArr = ['奖项', '中奖条件', '图示', '奖金']

// 图示： 1: 红 0: 蓝
// # 分行

// 示例
// const example = {
//     time: '', // 开奖时间
//     rule: '', // 玩法规则
//     awardsSetting: '', // 奖项设置
//     awardsTitle: wholeTitleArr,
//     awards: [ // 奖项列表
//         [
//             '',
//             '',
//             '',
//             '',
//         ],
//         [
//             '',
//             '',
//             '',
//         ],
//     ]
// }

const ssq = {
    time: "每周二、四、日21:15分开奖",
    rule: "6个红球+1个蓝球=1注（2元）",
    // awardsSetting: '', // 奖项设置
    awardsTitle: wholeTitleArr,
    awards: [ // 奖项列表
        [
            '一等奖',
            '中6红+1蓝',
            '1111110',
            '浮动奖金',
        ],
        [
            '二等奖',
            '中6红',
            '111111',
            '浮动奖金',
        ],
        [
            '三等奖',
            '中5红+1蓝',
            '111110',
            '3000元',
        ],
        [
            '四等奖',
            '中5红或中4红+1蓝',
            '11111#11110',
            '200元',
        ],
        [
            '五等奖',
            '中4红或中3红+1蓝',
            '1111#1110',
            '10元',
        ],
        [
            '六等奖',
            '中2红+1蓝或中1红+1蓝或中1蓝',
            '110#10#0',
            '5元',
        ],
    ]
}

const sevenLC = {
    time: "每周一、三、五  21:15分开奖",
    rule: "从30个号码中选择7个号码=1注（2元）",
    awardsTitle: wholeTitleArr,
    awardsIntro: '开奖时先开出7个号码作为基本号码，再摇出1个号码作为特别号码',
    awards: [
        [
            '一等奖',
            '中7个基本号码',
            '1111111',
            '浮动奖金#封顶500万',
        ],
        [
            '二等奖',
            '中6个基本号码+特别号码',
            '1111110',
            '浮动奖金',
        ],
        [
            '三等奖',
            '中6个基本号码',
            '111111',
            '浮动奖金',
        ],
        [
            '四等奖',
            '中5个基本号码+特别号码',
            '111110',
            '200元',
        ],
        [
            '五等奖',
            '中5个基本号码',
            '11111',
            '50元',
        ],
        [
            '六等奖',
            '中4个基本号码+特别号码',
            '11110',
            '10元',
        ],
        [
            '七等奖',
            '中4个基本号码',
            '1111',
            '5元',
        ],
    ]
}

const fc3D = {
    time: "天天开奖",
    rule: "每期从000-999的数字中开出一个3位数作为中奖号码=1注（2元）",
    awardsTitle: simpleTitleArr,
    awards: [
        [
            '直选',
            '投注号码与当期开奖号码按位全部相同（百位+十位+个位），即中奖',
            '1040元',
        ],
        [
            '组选三',
            '当期开奖号码的三位数中任意两位数字相同，且投注号码与当期开奖号码相同（顺序不限），即中奖',
            '346元',
        ],
        [
            '组选六',
            '当期开奖号码的三位数各不相同，且投注号码与当期开奖号码相同（顺序不限），即中奖',
            '173元',
        ],
        [
            '直选-和值',
            '投注号码与当期开奖号相加之和相同，且符合直选玩法规则，即中奖',
            '1040元',
        ],
        [
            '组选三-和值',
            '投注号码与当期开奖号相加之和相同，且符合组选三玩法规则，即中奖',
            '346元',
        ],
        [
            '组选六-和值',
            '投注号码与当期开奖号相加之和相同，且符合组选六玩法规则，即中奖',
            '173元',
        ],
        [
            '通选#2',
            '投注号码与当期开奖号码按位全部相同（百位+十位+个位），即中奖',
            '470元',
        ],
        [
            '投注号码与当期开奖号码中任意两个位置的号码按位相同，即中奖',
            '21元',
        ],
        [
            '和数',
            '投注号码与当期开奖号码的三个号码相加之和相同，即中奖',
            '14-1040元',
        ],
        [
            '包选三#2',
            '投注号码的三位数中任意两位数字相同，且投注号码与当期开奖号码按位全部相同，即中奖',
            '693元',
        ],
        [
            '投注号码的三位数中任意两位数字相同，且投注号码与当期开奖号码全部相同（顺序不同），即中奖',
            '173元',
        ],
        [
            '包选六#2',
            '投注号码的三位数各不相同，且投注号码与当期开奖号码按位全部相同，即中奖',
            '606元',
        ],
        [
            '投注号码的三位数各不相同，且投注号码与当期开奖号码全部相同（顺序不同），即中奖',
            '86元',
        ],
        [
            '1D',
            '投注号码与当期开奖号码中对应位置的号码相同，即中奖',
            '10元',
        ],
        [
            '猜1D#3',
            '投注号码与当期开奖号码中任意一个位置的号码相同，即中奖',
            '2元',
        ],
        [
            '投注号码与当期开奖号码中任意两个位置的号码相同，即中奖',
            '12元',
        ],
        [
            '投注号码与当期开奖号码中全部三个位置的号码相同，即中奖',
            '230元',
        ],
        [
            '2D',
            '投注号码与当期开奖号码中对应两个位置的号码按位相同，即中奖',
            '104元',
        ],
        [
            '猜2D#2',
            '投注号码为两个相同的号码，若当期开奖号码中包含投注的两个相同号码，即中奖',
            '37元',
        ],
        [
            '投注号码为两个不同的号码，若当期开奖号码中包含投注的两个不同号码（顺序不限），即中奖',
            '19元',
        ],
        [
            '猜大小',
            '投注号码与当期开奖号码的三个号码相加之和的大、小性质相同，即中奖。其中，三个号码相加之和在19（含）至27（含）之间时为大，在0（含）至8（含）之间时为小',
            '6元',
        ],
        [
            '猜奇偶',
            '当期开奖号码的三个号码全部为奇数或偶数，且投注号码与当期开奖号码的三个号码的奇数、偶数性质相同，即中奖。其中，1、3、5、7、9为奇，0、2、4、6、8为偶',
            '8元',
        ],
        [
            '拖拉机',
            '当期开奖号码的三个号码为以升序或降序连续排列的号码（890、098、901、109除外），即中奖',
            '65元',
        ],
        [
            '猜三同',
            '当期开奖号码为三个相同的号码，即中奖',
            '104元',
        ],
    ]
}

const cx4 = {
    time: "天天开奖",
    rule: "每期从0000-9999的数字中开出一个4位数作为中奖号码=1注（2元）",
    awardsTitle: simpleTitleArr,
    awards: [
        [
            '直选',
            '投注号码与开奖号码按位相符，即中奖',
            '10000元',
        ],
        [
            '组选4',
            '投注号码与开奖号码相同，顺序不限，即中奖',
            '2500元',
        ],
        [
            '组选6',
            '投注号码与开奖号码相同，顺序不限，即中奖',
            '1600元',
        ],
        [
            '组选12',
            '投注号码与开奖号码相同，顺序不限，即中奖',
            '800元',
        ],
        [
            '组选24',
            '投注号码与开奖号码相同，顺序不限，即中奖',
            '400元',
        ],
    ]
}

const fifteenChooseFive = {
    time: "天天开奖",
    rule: "从15个号码中选择5个号码=1注（2元）",
    awardsTitle: simpleTitleArr,
    awards: [
        [
            '特等奖',
            '投注号码与当期五个中奖号码（其中至少有四个号码为连号）全部相同（顺序不论，下同），即中奖',
            '浮动奖金#封顶500万',
        ],
        [
            '一等奖',
            '投注号码与当期五个中奖号码全部相同，即中奖',
            '浮动奖金',
        ],
        [
            '二等奖',
            '投注号码与当期中奖号码中任意四个号码相同，即中奖',
            '10元',
        ],
    ]
}

const sixPlusOne = {
    time: "每周一、三、六开奖",
    rule: "6个自然数的基本号码（从000000-999999中选择）+1个生肖号码=1注（2元）",
    awardsTitle: wholeTitleArr,
    awards: [
        [
            '一等奖',
            '6位基本码+生肖码按位相符',
            '1111110',
            '浮动奖金',
        ],
        [
            '二等奖',
            '6位基本码按位相符',
            '111111',
            '浮动奖金',
        ],
        [
            '三等奖',
            '5位基本码+生肖码按位相符',
            '111110',
            '10000元',
        ],
        [
            '四等奖',
            '5位基本码按位相符或4位基本码+生肖码按位相符',
            '11111#11110',
            '500元',
        ],
        [
            '五等奖',
            '4位基本码按位相符或3位基本码+生肖码按位相符',
            '1111#1110',
            '50元',
        ],
        [
            '六等奖',
            '3位基本码按位相符或2位基本码+1位生肖码按位相符或1位基本码+生肖码按位相符',
            '111#110#10',
            '5元',
        ],
    ]
}

export const bonusSettingMap = new Map()
bonusSettingMap.set(11, ssq)
bonusSettingMap.set(51, fc3D)
bonusSettingMap.set(24, sevenLC)
bonusSettingMap.set(61, sixPlusOne)
bonusSettingMap.set(52, cx4)
bonusSettingMap.set(23, fifteenChooseFive) 