export default [
    {
        path: '/distributionSSQ',
        name: 'distributionSSQ',
        component: () => import('../pages/chart/distributionSSQ'),
        meta: {
          title: '双色球分布图',
          isLogin: false,
          keepAlive: false
        }
      },
      {
        path: '/distributionQLC',
        name: 'distributionQLC',
        component: () => import('../pages/chart/distributionQLC'),
        meta: {
          title: '七乐彩分布图',
          isLogin: false,
          keepAlive: false
        }
      },
      {
        path: '/distributionKL8',
        name: 'distributionKL8',
        component: () => import('../pages/chart/distributionKL8'),
        meta: {
          title: '快乐8分布图',
          isLogin: false,
          keepAlive: false
        }
      },
      {
        path: '/distribution3D',
        name: 'distribution3D',
        component: () => import('../pages/chart/distribution3D'),
        meta: {
          title: '福彩3D分布图',
          isLogin: false,
          keepAlive: false
        }
      },
      {
        path: '/distribution4D',
        name: 'distribution4D',
        component: () => import('../pages/chart/distribution4D'),
        meta: {
          title: '天天彩选4分布图',
          isLogin: false,
          keepAlive: false
        }
      },
      {
        path: '/distributionG15',
        name: 'distributionG15',
        component: () => import('../pages/chart/distributionG15'),
        meta: {
          title: '15选5分布图',
          isLogin: false,
          keepAlive: false
        }
      },
      {
        path: '/distributionDF61',
        name: 'distributionDF61',
        component: () => import('../pages/chart/distributionDF61'),
        meta: {
          title: '东方6+1分布图',
          isLogin: false,
          keepAlive: false
        }
      },
      {
        path: '/distributionIndex',
        name: 'distributionIndex',
        component: () => import('../pages/chart/distributionIndex'),
        meta: {
          title: '数据中心 | 数据图表',
          isLogin: false,
          keepAlive: false
        }
      },
      {
        path: '/chooseDistributionType',
        name: 'chooseDistributionType',
        component: () => import('../pages/chart/chooseDistributionType'),
        meta: {
          title: '数据中心 | 数据图表',
          isLogin: false,
          keepAlive: false
        }
      },
]