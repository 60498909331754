export const phoneProcess = {
    titles: [
        [1, '投注'],
        [2, '密码修改及帐户类服务'],
        [3,'短信投注简介'],
        [4,'银行账户投注简介'],
        [5,'投注帐户遗忘查询'],
        [6,'最新公告'],
        [8,'最新开奖公告查询'],
        [0,'电话投注简介、退出请挂机']],
    content: [
        [
            '请输入投注卡号或投注账号以#号结束。',
            '请输入投注密码，以#号结束。'
        ],
        [
            '请输入投注卡号或投注账号以#号结束;',
            '请输入投注密码，以#号结束；',
            '如遇问题请拨打：021-64175076。'
        ],
        [
            '请选择1、什么是短信投注 2、短信开户方法查询3、短信投注方法查询4、如何绑定手机5、玩法代码查询0、短信客户服务电话 *号键返回'
        ],
        [
            '请选择1、交通银行2、民生银行3、农业银行4、招商银行5、农村商业银行6、浦发银行7、建设银行、8、工商银行、9、广发银行0、银行客户服务电话 *号键返回'
        ],
        [
           '请输入您的身份证号码，以#结束-您输入的身份证号码为**， -您共有投注帐户*个 -请记录与您身份证对应的投注账户，第一个**，所有账户复述完毕'
        ],
        [
            '感谢您的关注，上期活动已结束， 请您密切关注 下期活动。'
        ],
        [
            '双色球上期的开奖号码为，红色球号码：010203040506蓝色球号码：07，当前奖池奖金**元。七乐彩上期的开奖号码为：21222324252627特别号码28 ，当前奖池奖金**元。3D上期的开奖号码为467，天天彩选4上期的开奖号码为5678。15选5上期的开奖号码为0103051622，当前奖池奖金**元。东方6+1上期的开奖号码为:基本号码123456，生肖号码牛. 当前奖池奖金**元。'
        ],
        [
            '请选择1、什么是电话投注2、电话投注的特点3、卡号及投注密码遗忘4、身份证件号码输错5、电话投注的截止时间6、如何查询开奖公告7、兑领奖金*号键返回。'
        ]
    ],
}