import { initShowBalls, saveLotteryData, changeBallsStatus, jumpTo } from "..";
import { chooseNumberArr } from "../../../common-data";
import { calcPour } from "../../calc-pour";

// 重新选择本号码逻辑
// 获取用户选中的号码索引
// 初始化显示号码数据
// 根据用户选中号码索引修改展示号码选中状态
// 保存号码

// const cx4ModeData = [
//     {
//         modeIndex: 1,
//         repeatData: '0,1,2'
//     },
//     {
//         modeIndex: 2,
//         repeatData: '0,1#2,3'
//     },
//     {
//         modeIndex: 3,
//         repeatData: '1,2'
//     },
//     {
//         modeIndex: 4,
//         repeatData: ''
//     }
// ]

// cx4模式
function getCX4Mode(betNuber) {
    const b = betNuber?.split(',')
    if(!b) {
        return -1
    }
    if (b[0] == b[1] && b[1] == b[2]) {
        return 1
    } else if (b[0] == b[1] && b[2] == b[3]) {
        return 2
    } else if (b[0] == b[1]) {
        return 3
    } else {
        return 4
    }
}

// 3D组选单式
function get3DSingleGroup(betNumber) {
    const b = betNumber?.split(',')
    return (b[0] != b[1]) && (b[1] != b[2]) ? 2 : 1 
}

// 3D包选单式
function get3DContain(betNuber) {
    const b = betNuber?.split(',')
    return (b[0] != b[1]) && (b[1] != b[2]) && (b[0] != b[2]) ? 11 : 10  
}

// 获取投注模式
export function getMode(gameId, orderNumber, betNumber) {
    const Lkind = chooseNumberArr.get(parseInt(gameId)) // 彩种
    const modeIndex = orderNumber == 'a0342' ? get3DSingleGroup(betNumber) 
        : orderNumber == 'a0369' ? get3DContain(betNumber)
        : orderNumber == 'a04142' ? getCX4Mode(betNumber) 
        : Lkind.modes?.findIndex(ele => ele.orderNumber == orderNumber || (Array.isArray(ele.orderNumber) && ele.orderNumber?.findIndex(o => o == orderNumber) > -1))
    Lkind.modeIndex = modeIndex
    const LKindMode = Lkind.modes?.[modeIndex]
    console.log(LKindMode)
    return {
        modeIndex, LKindMode, Lkind
    }
}


// 获取投注数据
export function genBetNumberArr(arr, startNum, balls, orderNumber) {
    let _arr = [], len = balls?.length
    if (arr.indexOf('$') > -1) { // 胆拖
        const arr1 = arr.split('$')
        const arr2 = arr1[1]?.split('#')
        _arr = [arr1[0], ...arr2]
    } else {
        const arr1 = arr.split('#')
        if (arr1.length < len) {
            _arr = [...arr1[0]?.split(','), ...arr1.slice(1, arr1.length)]
        } else {
            _arr = [...arr1]
        }
    }
    console.log(_arr)

    const indexArr = []

    _arr.forEach(row => {
        const _row = []
        row = row?.split(',')
        row.forEach(col => {
            col = startNum == 0 ? parseInt(col) : parseInt(col) - 1
            if(!isNaN(col)) {
                _row.push(col)
            }
        })
        indexArr.push(_row)
    })
    const numberOne = indexArr[0][0]
    switch(orderNumber) {
        case 'a0624':// 特殊处理6+1
            indexArr[6][0] = parseInt(indexArr[6][0]) - 1
            break
        case 'a0364': // 特殊处理奇偶
            indexArr[0][0] = numberOne == 5 ? 0 : 1
            break
        case 'a0363': // 特殊处理大小
            indexArr[0][0] = numberOne == 2 ? 0 : 1
            break
    }
    return indexArr
}

// 判断投注数组玩法模式是否一致
export function judgeModeIndexIsSame(gameId, orderNumber, betNumbers) {
    const indexArr = []
    betNumbers.forEach(ele => {
        const {modeIndex} = getMode(gameId, orderNumber, ele)
        indexArr.push(modeIndex)
    })
    const indexOne = indexArr?.[0]
    return indexArr.every(ele => ele == indexOne)
}

function dealWithSpecialNumer(type, choosedIndex) {
    switch(type) {
        case 'cx4Group':
            {
                let arr = [...new Set(choosedIndex.split(','))]
                return arr.join(',')
            }  
        default:
            return choosedIndex
    }
}

export function genChoosedData(gameId, orderNumber, choosedIndex) {
    const {LKindMode, modeIndex} = getMode(gameId, orderNumber, choosedIndex?.[0])
    initShowBalls(LKindMode)
    LKindMode.betNumberArr = []
    LKindMode.betNumberIndexArr = []
    choosedIndex.forEach((arr, arrIndex) => {
        arr = dealWithSpecialNumer(LKindMode.type, arr)
        changeBallsStatus(genBetNumberArr(arr, LKindMode.startNum, LKindMode.balls, orderNumber), LKindMode)
        saveLotteryData(LKindMode, arrIndex, calcPour(LKindMode, gameId))
        if (orderNumber == 'a0342') { // 特殊玩法
            LKindMode.betNumberArr[arrIndex] = {redBalls: arr.split(',')}
        }
    })
    jumpTo(`/LList?gameId=${gameId}&modeId=${modeIndex}`)
}