import { toast } from "."
import { resetUserInfo } from "../api"

export function changeValue(hidden) {
    hidden = !hidden
    console.log(hidden)
    return hidden
}

export async function fillName(name, idCard) {
    try {
        console.log(name, idCard)
        if (name == void 0 || name == '') {
            toast('真实姓名不能为空')
            return
        }
        const result = await resetUserInfo({realName: name, cardNumber: idCard})
        if (result.code == 0) {
            return true
        }
        return false
    } catch(e) {
        console.error(e)
    }
}

      // 判断是否完善信息
export function judgeIsFillName(userInfo) {
    if (userInfo.storeId != '81666' && !userInfo.realName) {
        return true
    }
    return false
}

