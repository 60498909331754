<template>
    <div class='no_data'>
        <img src="../../assets/imgs/no-data.png" alt="">
        <p>{{tip}}</p>
        <!-- <p>如需获取更多历史记录</p> -->
        <!-- <p>请登录 www.81666.net 网站查询</p> -->
    </div>
</template>
<script>
export default {
    name: '',
    props: {
        tip : {
            type: String,
            default: '暂无数据...'
        }
    }
}
</script>
<style lang='scss' scoped>
.no_data {
    text-align: center;
    padding: 65px 0 15px 0;
    img {
        width: 150px;
        height: auto;
    }
    p {
        font-size: $font-small;
        color: $gray-dark;
        padding-top: 5px;
        opacity: .3;
    }
}
</style>