import { createApp } from 'vue'
import App from './App.vue'
import {
    Tabbar,
    TabbarItem,
    Swipe,
    SwipeItem,
    Icon,
    NavBar,
    Button,
    Collapse,
    CollapseItem,
    Overlay,
    List,
    Empty,
    DropdownMenu,
    DropdownItem,
    Image as VanImage,
    DatetimePicker,
    ActionSheet,
    Field,
    Popup,
    Picker,
    Radio,
    RadioGroup,
    Form,
    Loading,
    PullRefresh,
    Divider,
    Switch,
    Stepper,
    Checkbox,
    Tab,
    Tabs,
    Calendar
} from 'vant';
// import Calendar from 'vue-mobile-calendar'
const app = createApp(App)
app.use(Calendar)
app.use(List);
app.use(Overlay);
app.use(NavBar);
app.use(Icon);
app.use(Swipe);
app.use(SwipeItem);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Button);
app.use(Collapse);
app.use(CollapseItem);
app.use(Empty);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(VanImage);
app.use(DatetimePicker);
app.use(ActionSheet);
app.use(Field);
app.use(Popup);
app.use(Picker);
app.use(Radio);
app.use(RadioGroup);
app.use(Form);
app.use(Loading);
app.use(PullRefresh);
app.use(Divider);
app.use(Switch);
app.use(Stepper);
app.use(Checkbox);
app.use(Tab);
app.use(Tabs);

export default app


// app.prototype.$Toast.allowMultiple()